import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {  useLocation, useParams } from "react-router-dom";
import { Grid, Typography, Container, Avatar, Paper} from "@material-ui/core";
import LeftSideBar from "../components/LeftSideBar";
import {useHistory} from 'react-router-dom';
import NavBar from "../components/Navbar";
import axios from 'axios'
import { API_URL, IMAGE_URL } from "../Constants";
import BannerImg from '../images/my-banner.png'
import ShopRightSidebar from "../components/Shops/ShopRightSidebar";
import PublicProfile from "../components/my-profile/PublicProfile";
import UserRightSidebar from "../components/my-profile/UserRightSidebar";


const useStyles = makeStyles(theme => ({
  box:{
    position: 'relative'
},
text:{
    position: 'absolute',
    zIndex: 999,
    margin: '0 auto',
    left: 0,
    right: '40%',
    top:'70%',      
    textAlign: 'center',
    fontFamily: 'Arial,sans-serif',
    color: '#fff',
   
}

}));
export default function ProfileLayout() {

  const location = useLocation()

  let [user,setUser]=React.useState({})
  let { link } = useParams();
  const classes = useStyles();
  const history=useHistory();
  const [isAuthenticated,setIsAuthenticated]=React.useState(false);
  const [loading,setLoading]=React.useState(false)


  React.useEffect(()=>{
    if(localStorage.getItem('user')) setIsAuthenticated(true);
    setLoading(true)
      axios.get(API_URL+'UserInfoes/Details?link='+link).then(res=>{
       
       // console.log(res.data.Data);
       // console.log(link)
        setUser(res.data.Data)
        setLoading(false);
        document.title=res.data.Data?.Name
      })
  },[])

const  handleAuthenticate=()=>{
    setIsAuthenticated(false)
  }

  return (
    loading?<h6 >loading......</h6>:
    <div style={{backgroundColor:'#F2F2F2',width: '100%', height: '100%',position: 'absolute', top: 0, left: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'}}>
      <Grid container>
          <Grid item> <NavBar authenticate={handleAuthenticate} isAuthenticated={isAuthenticated} /></Grid>
      </Grid>
      <Container maxWidth="xl" style={{paddingTop: '75px',backgroundColor:'#F2F2F2'}}>
      <Grid container spacing={3} >
          <Grid item md={3} lg={3} style={{margin:'auto',paddingLeft:'70px'}}> 
          <Avatar alt= {user?.Name} style={{width:220,height:220,marginBottom:'10px'}} src={IMAGE_URL+user?.ProfilePicturePath} />
  <Paper style={{width:200,height:35,borderRadius:25}} elevation={3} ><Typography align="center" color="primary" variant="h6">{user?.Name}</Typography></Paper>
          </Grid>
          <Grid item md={9} lg={9}> 
              <img width="100%" height="350" src={user?.BannerPicturePath==='/Banner/1200/'?BannerImg: IMAGE_URL+user?.BannerPicturePath}/>
          </Grid>
      </Grid>
      </Container>

      <Container maxWidth="xl" style={{backgroundColor:'#F2F2F2'}}>
    <Grid container spacing={1}>
      <Grid item xl={3} lg={3} md={3} sm={3}>
        <LeftSideBar isAuthenticated={isAuthenticated}/>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <div className="main"><PublicProfile userId={user?.Id}/></div>
          </Grid>
        </Grid>
      </Grid>

      <Grid  item xl={3} lg={3} md={3} sm={3}>      
            <UserRightSidebar userId={user?.Id}/>       
      </Grid>
    </Grid>
    </Container>
    </div>
  );
};
