import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles,withStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Dropzone from "react-dropzone";
import "./dropzone.css";
import { Typography, GridList, GridListTile, Container, Input } from "@material-ui/core";
import axios from 'axios';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { API_URL, Base_Image_URL, IMAGE_URL } from "../../Constants";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
        
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  gridListDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  triangleBottomRight:{
    width:0,
    height:0,
    borderRight:'150px solid red',
    borderTop:'40px solid transparent',
    borderLeft:'400px solid transparent',
    borderBottom:'60px solid red',
    
},
flexContainer:{
  display:'flex',
  justifyContent:'flex-end',
}
}));
const PostAdForm = props => {
  const classes = useStyles();
  const {
    values: { Title, RootCategoryId,SubCategoryId, SubSubCategoryId,AskingPrice, MarketPrice,Description },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,  
    setFieldValue
  } = props;

      let [rootCategories,setRootCategories]=React.useState([])
      let [subCategories1,setSubCategories1]=React.useState([])
      let [subCategories2,setSubCategories2]=React.useState([])
      let [tileData,setTileData]=React.useState([])
      let [hasSubCategory1,setHasSubCategory1]=React.useState(false)
      let [hasSubCategory2,setHasSubCategory2]=React.useState(false)
      let [allImageIds,setAllImageIds]=React.useState([])
      let [uploadPercentage,setUploadPercentage]=React.useState(0)
      React.useEffect(() => {
        axios.get(API_URL+'ProductCategories/All')
         .then(response => {
           console.log(response)
           setRootCategories(response.data)
             
        });  
       }, []);
 
       const handleDrop = acceptedFiles =>{
         // axios upload options
        const options = {
          onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor( (loaded * 100) / total )
            console.log( `${loaded}kb of ${total}kb | ${percent}%` );
    
            if( percent <= 100 ){
              setUploadPercentage(percent);
            }
          }
        }
       
               for (let i = 0; i < acceptedFiles.length; i += 1) {
                 let reader=new FileReader();
                 reader.readAsDataURL(acceptedFiles[i]);
       
                 reader.onloadend=e=>{
                   let imageCode = reader.result.toString().split(',')[1];
                   console.log(imageCode);          
                    axios.post(API_URL+'Uploads/Photo',{Title:acceptedFiles[i].name.toString().split('.')[0],Code:imageCode,Type:'Product'},options).then(res=>{
                      toast.warn("Image Uploading");
                        setUploadPercentage(0);
                          let tile={
                          Id:res.data.Data.Id,
                            Filename:res.data.Data.Filename,
                            SeoFileName:res.data.Data.SeoFileName
                         }
                         setAllImageIds(allImageIds=>[...allImageIds,tile.Id.toString()])
                         setTileData(tileData => [...tileData, tile])
                        
                   })
                 }
               }
               
             }
       
  const change = (name, e) => {
    console.log(e);
    console.log(name);
    if(name=='RootCategoryId'){   
      let categoryId=e.target.value
      axios.get(API_URL+'ProductCategories/All?parentId='+categoryId)
      .then(response => {
        if (response.data.length!=0){
          setHasSubCategory1(true);
          setHasSubCategory2(false);
          setSubCategories1(response.data)
        }else{
          setHasSubCategory1(false);
         
        }
      
     });
    }
    if(name=='SubCategoryId'){
      let categoryId=e.target.value;
      axios.get(API_URL+'ProductCategories/All?parentId='+categoryId)
      .then(response => {
        if (response.data.length !=0){
          setHasSubCategory2(true);
          setSubCategories2(response.data)
         
        }else{
          setHasSubCategory2(false);
        }
     });
    }

    e.persist();
    handleChange(e);

    setFieldTouched(name, true, false);
  };


  
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
       <Container maxWidth='sm'>
    <Grid container>
       <Grid item sm={8} md={8}>
     
  
    <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            variant="outlined"
            id="Title"
            name="Title"
            helperText={touched.Title ? errors.Title : ""}
            error={errors.Title && Boolean(errors.Title)}
            label="Title"
            fullWidth
            size="small"
            value={Title}
            onChange={change.bind(null, "Title")}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            select
            id="RootCategoryId"
            label="Select Category"
            helperText={touched.RootCategoryId ? errors.RootCategoryId : ""}
            error={errors.RootCategoryId && Boolean(errors.RootCategoryId)}
            name="RootCategoryId"
            value={RootCategoryId}
            onChange={change.bind(null, "RootCategoryId")}
            variant="outlined"
            fullWidth
            size="small"
          >
            {rootCategories.map(option => (
           
              <MenuItem key={option.Id} value={option.Id} >
                {option.Title}
              
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>
        {hasSubCategory1? <Grid item xs={12}>
          <CustomTextField
            select
            id="SubCategoryId"
            label="Select Sub Category"
            name="SubCategoryId"
            value={SubCategoryId}
            onChange={change.bind(null, "SubCategoryId")}
            variant="outlined"
            fullWidth
            size="small"
          >
            {subCategories1.map(option => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.Title}
           
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>:null}
        {hasSubCategory2? <Grid item xs={12}>
          <CustomTextField
            select
            id="SubSubCategoryId"
            label="Select Sub Category"
            name="SubSubCategoryId"
            value={SubSubCategoryId}
            onChange={change.bind(null, "SubSubCategoryId")}
            variant="outlined"
            fullWidth
            size="small"
          >
            {subCategories2.map(option => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.Title}
             
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>:null}
       
        <Grid item xs={12} sm={6}>
          <CustomTextField
            variant="outlined"
            id="AskingPrice"
            name="AskingPrice"
            type="number"
            helperText={touched.AskingPrice ? errors.AskingPrice : ""}
            error={errors.AskingPrice && Boolean(errors.AskingPrice)}
            label="AskingPrice"
            fullWidth
            size="small"
            value={AskingPrice}
            onChange={change.bind(null, "AskingPrice")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            variant="outlined"
            id="MarketPrice"
            type="number"
            name="MarketPrice"
            helperText={touched.MarketPrice ? errors.MarketPrice : ""}
            error={errors.MarketPrice && Boolean(errors.MarketPrice)}
            label="MarketPrice"
            fullWidth
            size="small"
            value={MarketPrice}
            onChange={change.bind(null, "MarketPrice")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            rows="4"
            rowsMax="50"
            variant="outlined"
            fullWidth
            size="small"
            helperText={touched.Description ? errors.Description : ""}
            error={errors.Description && Boolean(errors.Description)}
            id="Description"
            value={Description}
            label="Description"
            name="Description"
            onChange={change.bind(null, "Description")}
          />
        </Grid>
      
      </Grid>
   
       </Grid>
  
       <Grid item sm={4} md={4} style={{paddingLeft:'20px'}}>
       <Dropzone
        onDrop={handleDrop}
        accept="image/*"
        minSize={1024}
        maxSize={3072000}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject
        }) => {
          // additional CSS depends on dragging status
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
            ? "reject"
            : "";

          return (
            <div
              {...getRootProps({
                className: `dropzone ${additionalClass}`
              })}
            >
              <input {...getInputProps()} />
              <span>{isDragActive ? "📂" : "📁"}</span>
              <Typography variant="body1" color="primary">Drag'n'Drop  or Click to Upload Images</Typography>
            </div>
          );
        }}
      </Dropzone>
      {uploadPercentage >0 ? <CircularProgressbar value={uploadPercentage} text={`${uploadPercentage}%`} />:null}
     
        </Grid>
    </Grid>
    </Container>
    <Container maxWidth='sm' style={{paddingTop:20}}>
    <Grid container>
      <Grid item md={12}>
    
                       <GridList cols={4} rows={2} cellHeight={80}>
                        {
                           tileData.map((tile,i) => (
                            <GridListTile style={{ display: 'flex', flexFlow: 'no-wrap column' }}  key={i}  >
                               <img src={IMAGE_URL+ new Date().getFullYear()+(new Date().getMonth()+1).toString().padStart(2,"0")+'/product/200/'+tile.Filename}  alt={tile.SeoFileName} />
                            </GridListTile>
                          ))
                        }
                        </GridList>
      </Grid>

    </Grid>
    </Container>
    <div className={classes.flexContainer}>
          <div  className={classes.triangleBottomRight}> 
          <Input
            type="hidden"
            name="AllImageIds"
          />
          <Button onClick={() => {setFieldValue("AllImageIds", {allImageIds}); }} type="submit" color="secondary"  style={{ borderRadius: 25,textTransform: "none" ,width:130}}  disabled={!isValid} variant="contained">Post Ad</Button>
                   </div>
          </div>
    <ToastContainer autoClose={3000} />
    </form>
 
   );
};




export default PostAdForm;
