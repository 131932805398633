import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, ButtonBase, Button } from "@material-ui/core/";
import IMG from '../../images/House2.jpg';
import Avatar from "@material-ui/core/Avatar";
import avatar from "../../images/avatar.jpg";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import PIC1 from "../../images/pic1.png";
import PIC2 from "../../images/pic2.jpg";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import FsLightbox from "fslightbox-react";
//import Link from '@material-ui/core/Link';
import { Link, useHistory } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import axios from 'axios';
import { API_URL, Base_Image_URL } from "../../Constants";
import { BASEIMAGE } from "../../Constants";
import EditAdDialog from "./EditAdDialog";
import AddCommentFormik from "../comments/AddComment";
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import Moment from 'react-moment';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    color: '#FFF',
    '&:hover': {
        color: '#ddd'
  }},
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    marginBottom: 10,
    
  },
  image: {
    width: 250,
   // heght:250
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
 
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  gridList: {
    width: 500,
    height: 180
  },
  gridDIv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  spec: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
   
  },
}));

 const  AdCard=({item,key,displayEditBtn})=> {
   //console.log(new Date(item.UpdatedAt));
  const classes = useStyles();
  const history=useHistory();
  const [lightboxController, setLightboxController] = React.useState({
    toggler: false,
    slide: 1
  });
  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  
  }



  // for edit option
  const [isOpen, setIsOpen] = React.useState(false);
  const [displayComments,setDisplayComments]=React.useState(false)
  const displayComment = () => setDisplayComments(!displayComments);
  

const handleDialogOpen = () => {
  if(!localStorage.getItem('user')){
    history.push('/login')
}
    setIsOpen(true)
}

const handleDialogClose = () => {
    setIsOpen(false)
    
}

const handleUpdate=(guid)=>{
  axios.put(API_URL+'Products/Update?updatedBy='+JSON.parse(localStorage.getItem('user')).Id+'&guid='+guid).then(res=>{
      toast.success("Product Updated Successfully")
  }).catch(err=>{
   toast.error("Something went wrong")
  })
}

//end
  return (
    <div className={classes.root}>
            
          <Paper className={classes.paper}>
               
                <Grid container justify="space-between">
                  <Grid item md={1}>
                    <Avatar alt={item.CollectionName} src={Base_Image_URL+'Profile/'+item.CollectionImage} />
                  </Grid>
                  <Grid item md={8} >
                  
                    <Link to={item.CollectionLink} >{item.CollectionName}</Link>
                    <Typography variant="body2">{item.CollectionAddress}</Typography>
       
                  </Grid>
                  
                  <Grid item md={3}>
                    <Typography color="primary" variant="body2"><Moment format="D MMM YYYY HH:mm A" add={{ hours: 6 }}>{item.UpdatedAt}</Moment></Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    {   item.PictureListView.length==2?
                        <GridList cols={1} rows={2} cellHeight={60}>
                        {
                           item.PictureListView.map((file, i) => (
                            <GridListTile style={{ display: 'flex', flexFlow: 'no-wrap column' }}  key={i}  cols={i===0 ? 1 : 1} rows={i===0 ? 2 : 1}>
                              {
                               
                                <img onClick={() => openLightboxOnSlide(i + 1)} style={{cursor:'pointer'}} src={BASEIMAGE+file.FileLinkThumb} />
                              }
                          
                            </GridListTile>
                          ))
                        }
                        </GridList>:null
                    }

                      {   item.PictureListView.length>2?
                        <GridList cols={2} rows={2} cellHeight={60}>
                        {
                          item.PictureListView.map((file, i) => (                           
                            i>2?null:
                            <GridListTile style={{ display: 'flex', flexFlow: 'wrap row' }}  key={i}  cols={i===0 ? 2 : 1} rows={i===0 ? 2 : 1}>
                             
                             {                               
                                <img onClick={() => openLightboxOnSlide(i + 1)} style={{cursor:'pointer'}} src={BASEIMAGE+file.FileLinkThumb} />
                              }
                             
                            </GridListTile>
                          ))
                        }
                        </GridList>:null
                    }

                  { //if count=1
                       item.PictureListView.length==1?
                        <GridList cols={1} rows={1} cellHeight={60}>
                        {
                          item.PictureListView.map((file, i) => (
                            <GridListTile style={{ display: 'flex', flexFlow: 'wrap row' }}  key={i}  cols={i===0 ? 1 : 1} rows={i===0 ? 3 : 1}>
                              {                             
                                <img onClick={() => openLightboxOnSlide(i + 1)} style={{cursor:'pointer'}} src={BASEIMAGE+file.FileLinkThumb} />
                              }
                            </GridListTile>
                          ))
                        }
                        </GridList>:null
                    }

          { //if count=0
                       item.PictureListView.length==0?
                        <GridList cols={1} rows={1} cellHeight={60}>
                        {
                         
                            <GridListTile style={{ display: 'flex', flexFlow: 'wrap row' }}   cols={0===0 ? 1 : 1} rows={0===0 ? 3 : 1}>
                              {                   
                                <img style={{cursor:'pointer'}} src={BASEIMAGE+'/Upload/Default/sbx-product-default.png'} />
                              }
                            </GridListTile>
                        
                        }
                        </GridList>:null
                    }
                <FsLightbox
                   toggler={lightboxController.toggler}
                    sources={item.PictureListView.map(x=>BASEIMAGE+x.FileLinkBig)}  
                    slide={lightboxController.slide}
                    disableLocalStorage={true}
                    key={ item }
                    type="image"
                    />
                         
                
                  </Grid>
                  <Grid item xs={12} sm={8} container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                      <div className={classes.spec}>
                   <Typography component="h6" variant="subtitle2" component="h6" color="primary">
                    {item.Title}
                    </Typography>
                  
                   </div>
                      <div className={classes.spec}>
                   <Typography  variant="subtitle2" color="textPrimary">
                      Asking Price :
                    </Typography>
                    <Typography variant="subtitle2" color="primary">
                     &nbsp;{item.RegularPrice}
                    </Typography>
                    &nbsp;
                    <Typography variant="subtitle2" color="primary">
                    BDT
                    </Typography>
                   </div>

                       {
                         item.MarketPrice===0?null:
                         <div className={classes.spec}>
                         <Typography  variant="subtitle2" color="textPrimary">
                            Market Price : 
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                          &nbsp;{item.MarketPrice}
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                          &nbsp;BDT
                         </Typography>
                          
                         </div>
                       }

                  
                   <div className={classes.spec}>
                           <Typography  variant="subtitle2" color="textPrimary">
                          Category : 
                          </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                    {item.ProductCategoryTitle}
                    </Typography>
                   </div>
                   <div className={classes.spec}>
                   <ShowMoreText
                /* Default options */
                lines={3}
                more='Show more..'
                less='Show less'
                anchorClass=''
            
                expanded={false}
                width={500}
            >    
               <div  dangerouslySetInnerHTML={{__html: item.Description}} />
            </ShowMoreText>
                      </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify='space-between' style={{marginTop:'10px'}}>
                  {/* <Grid item>
                   <Button size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="outlined"><RateReviewOutlinedIcon/>&nbsp;Review</Button>
                   </Grid> */}
                    <Grid item>
                  <Button size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="outlined" onClick={displayComment}><ChatBubbleOutlineOutlinedIcon/>&nbsp;Comment({item.CommentCount})</Button>
                    </Grid>
                    {/* <Grid item>
                  <Button size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="outlined"><FavoriteBorderOutlinedIcon/>&nbsp;Favourite</Button>
                    </Grid> */}
                    {/* <Grid item>
                  <Button size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="outlined"><ThumbUpOutlinedIcon/>&nbsp;Wishlist</Button>
                    </Grid> */}
                    <Grid item>
                   <Button   onClick={()=>{ const win = item.SystemName==null?window.open("/"+item.GUID, "_blank"):
                               window.open("/"+item.SystemName, "_blank");win.focus();}} 
                                  size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="contained" color="primary">Details</Button> 
                    {/* <Button   onClick={()=>{const win = window.open("/"+item.GUID, "_blank");
                                win.focus();}}  size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="contained" color="primary">Details</Button> */}
                  </Grid>
                  {
                    (localStorage.getItem('user') && displayEditBtn)?
                    <Grid item>
                    <Button  onClick={(e)=>handleUpdate(item.GUID,e)} size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="contained" color="primary">Update</Button>
                  </Grid>:null                    
                  }

                {
                    (localStorage.getItem('user') && displayEditBtn)?
                    <Grid item>
                    <Button  onClick={handleDialogOpen} size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="contained" color="primary">Edit</Button>
                  </Grid>:null                    
                  }
                 

      </Grid>
              


              </Paper>
              {
              displayComments?  <AddCommentFormik productId={item.Id}/>:null
              }
            
              <EditAdDialog
                      isOpen={isOpen}
                      handleClose={handleDialogClose}
                      title='Edit Your Ad'
                      advertisementGuid={item.GUID}
                      >
                </EditAdDialog>
                <ToastContainer autoClose={2000} />
    </div>
  );
}

export default AdCard;