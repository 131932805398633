import React from 'react'
const { Switch, Route } = require("react-router-dom");
const { default: ProfileLayout } = require("../../layouts/ProfileLayout");



const ProfileRoute = ({match}) => {
    return(
      <Switch>
        <Route path={`${match.url}/:link`} exact={true} component={ProfileLayout} />
       
      </Switch>
    );
  };
  
  export default ProfileRoute;