import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from "../../Constants";
import LogoShop from '../../images/logo-shop1.png';
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonCard from "../../layouts/SkeletonCard";
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(50),
    display:'flex',
    justifyContent:'space-between',
    
  },
  triangleBottomRight:{
    width:0,
    height:0,
    borderRight:'110px solid #D1152C',
    borderTop:'35px solid transparent',
    borderLeft:'300px solid transparent',
    borderBottom:'50px solid #D1152C',
    
},
flexContainer:{
  display:'flex',
  justifyContent:'flex-end',
  alignItems:'flex-end'
},
spec: {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
 
},
}));

const ShopList = props => {
  const classes = useStyles();
  const history = useHistory();
  let [shops,setShops]=React.useState([])

  React.useEffect(()=>{
      axios.get(API_URL+'Shops/All').then(res=>{
          setShops(res.data)
      })
  },[])

  const [hasMore,setHasMore]=React.useState(true)
 let[page,setPage]=React.useState(0);
 const fetchMoreData=()=>{
   page++;
   setPage(page);
      axios.get(API_URL+'Shops/All?pageIndex='+page)
      .then(response => {
        if(response.data.length<10) setHasMore(false);
        setShops(shops.concat(response.data))
       
     });
      
  
  }
 
    return (
      <InfiniteScroll
      dataLength={shops.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<SkeletonCard/>}
      
      scrollableTarget="scrollableDiv"
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {
         shops?.map((shop,index)=>(
          <Paper className={classes.root} key={index}>
          
          <div style={{alignSelf:'center'}}> <Paper><img src={LogoShop}/></Paper></div>
          <div>
          <div className={classes.spec}>
                   <Typography  variant="subtitle2" color="primary">
                      Name:
                    </Typography>
                    <Typography variant="subtitle2" color="textPrimary">
                   {shop.Name}
                    </Typography>
                    
                   </div>
        
          <Typography variant="subtitle2">Type:{shop.UserTypeTitle}</Typography>
         <Typography variant="subtitle2">Contact:{shop.Phone}</Typography>
         <Typography variant="subtitle2">Website:{shop.Website}</Typography>
         <Typography variant="subtitle2">Facebook:{shop.FBPageLink}</Typography>
         <Typography variant="subtitle2">Address:{shop.Address}</Typography>
          </div>
          <div className={classes.flexContainer}>
          <div  className={classes.triangleBottomRight}> 
          <Button type="submit" onClick={()=>{history.push('/shop/'+shop.Id)}} color="secondary"  style={{ borderRadius: 25,textTransform: "none" ,width:100}} variant="contained">Details</Button>
                   </div>
          </div>
       </Paper>
         ))
      }
     
     </InfiniteScroll>
  );
};

export default withStyles(useStyles)(ShopList);
