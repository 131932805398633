import React from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import {
  withStyles,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Paper,
  Avatar,
  Grid
} from "@material-ui/core";
import IMG from "../../images/toppic.png";
import { useHistory } from "react-router-dom"
import axios from 'axios';
import { API_URL, BASEIMAGE, Base_Image_URL } from "../../Constants";

const styles = {
  card: {
    maxHeight:350,
    marginBottom:'10px'
  },
  
  media: {
    height: 150,
    marginLeft:15,
    marginRight:15
  },
  title: {
    fontSize: 15
  },
  rectangle:{
    height:'30px',
    background:'#D1152C',
    display:'flex',
    justifyContent:'center'
},



title2:{
   marginBottom:'10px'
},
marginBottom:{
marginBottom:'20px'
},
large: {
  width: 50,
  height: 50
},
showAllBtn:{
  borderRadius:25,
  textTransform: "none",
  padding:'6px 30px',
  marginTop:'10px',
  marginBottom:'15px',
  
},
hoverBtn:{
  borderRadius: 25,
  textTransform: "none",
  '&:hover': {
    background: "#D1152C",
    color:'#ffffff'
 }
},

shopLogo:{
  position:'relative',
  top:'-50px',
  left:'100px'
}
};

function UserFeaturedProducts(props) {
 const {userId}=props;
  const [loading, setLoading] = React.useState(false);
  let [featuredProducts,setFeaturedProducts]=React.useState([])
  React.useEffect(()=>{
   setLoading(true)
   axios.get(API_URL+'Products/UserFeatured?userId='+userId+'&pageSize='+3)
   .then(response => {
     console.log(response)
     setFeaturedProducts(response.data)
     setLoading(false)
  });
  },[])


  const { classes } = props;
  const history = useHistory();
 
  return (
    <div style={{marginTop:'0px'}}>  
        <div className={classes.rectangle} style={{borderTopLeftRadius:'4px',borderTopRightRadius:'4px'}}>
              <Typography variant="h6"  color="secondary">Featured List</Typography>
       </div>
       {!loading &&
          featuredProducts?.map((product,i)=>(
       <Card className={classes.card}>
        
        <CardContent>
          
        <Typography paragraph className={classes.title} color="primary">{product.Title}</Typography>
        </CardContent>
     {
       product.PictureListView.length >= 1?
      ( product.PictureListView[i]?.FilePath ==='/Upload//' ?
       <CardMedia
       className={classes.media}
       image={Base_Image_URL+'Product/Small/'+product.PictureListView[0]?.Filename}
       title={product.Title}
     />:   <CardMedia
     className={classes.media}
     image={BASEIMAGE+product.PictureListView[0]?.FileLinkSmall}
     title={product.Title}
   />):
     <CardMedia
     className={classes.media}
     image={IMG}
     title="advert"
   />

       
     }
          
          <CardContent>
          <Typography  variant="subtitle2">Asking Price : {product.RegularPrice} Tk</Typography>
          {
                         product?.MarketPrice===0?null:
          <Typography  variant="subtitle2">Market Price : {product.AskingPrice} Tk</Typography>
          }
          </CardContent>
          <CardActions>
          <Button  onClick={()=>{const win = window.open("/"+product.GUID, "_blank");
                                win.focus();}} size="small" style={{ borderRadius: 25 ,textTransform: "none",margin:'auto'}} variant="outlined" color="primary">Check Details</Button>
          </CardActions>
          
      
      </Card>
    ))}

    </div>
  );
}

UserFeaturedProducts.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserFeaturedProducts);
