

import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import EditProfile from "./EditProfile";
import axios from 'axios';
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
import { useParams,useHistory } from "react-router-dom";




const useStyles = theme => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
   // alignItems: 'center',
    maxWidth:700,
    maxHeight:1200,
   
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom:'20px'
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  topLabel:{
     width:'250px',
     height:'40px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
  },
  rightTopHalfCircle:{
    height:'40px',
    width:'20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
   
},
bottomRightTriangle:{
     width:'30px',
     height:'30px',
     background: '#D1152D',
     borderRadius: `90px 0 0px 0`
},

});
  
const EditProfileFormic =(props)=> {
  let { guid } = useParams();
  const history=useHistory();
  let[userInfo,setUserInfo]=React.useState({})
  React.useEffect(()=>{
    axios.get(API_URL+'UserInfoes/DetailsByGUID?guid='+guid).then(response=>{
      console.log(response)
         setUserInfo(response.data.Data)
    })
},[]);
  const  submit = data => {
    console.log(data)
    userInfo.Name= data.Name;
    userInfo.ProfilePictureId= data.ProfilePictureId; 
    userInfo.BannerPictureId= data.BannerPictureId;
    userInfo.Phone= data.Phone;
    userInfo.Email=data.Email;
    userInfo.StateId=data.StateId;
    userInfo.CityId=data.CityId;
    userInfo.Address=data.Address;
    userInfo.Password=""
    
    axios.put(API_URL+'UserInfoes/UpdateProfile?id='+userInfo.Id,userInfo).then(res=>{
      if(res.data.Meta.Status==1){
      toast.success(res.data.Meta.Message);
      setTimeout(()=>{
        history.push("/my-profile")
      },2000)
     
      }else{
        toast.error("Something Went Wrong");
      }
    })

  };

 

 const validationSchema = Yup.object({
    Name: Yup.string("Enter a name")
    .required("Name is required"),
    Phone: Yup.string("Enter a Phone Number")
    .required("Phone Number is required")
    .matches(
      /^(01[3456789])(\d{8})$/,
      "Please Enter valid Bangladeshi Phone Number"
    ),
    StateId: Yup.number("Select District")
    .required("District is required"),
    CityId: Yup.number("Select Thana")
    .required("Thana is required"),
    
  
   })

   const values = { Name: userInfo.Name, Phone: userInfo.Phone,Email: userInfo.Email,Address: userInfo.Address, StateId:userInfo.StateId,CityId:userInfo.CityId,ProfilePicPath:userInfo.ProfilePicturePath,BannerPicturePath:userInfo.BannerPicturePath,CreatedBy:1 };



  
    const { classes } = props;

    return (
      <Paper className={classes.paper}>

      <div className={classes.flexContainer}>
      <div className={classes.topLabel}>
      <Typography variant="body1"  color="secondary">
       Update Your Profile
     </Typography>  </div>
     <div className={classes.rightTopHalfCircle}>  </div>
      </div>
   
   
          <Formik
             enableReinitialize
            render={props => <EditProfile {...props} />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
          />
       <div className={classes.flexZone}>
      
      <div className={classes.bottomRightTriangle}>  </div>
       </div>
       <ToastContainer autoClose={2000} />
      </Paper>
    );
  }

export default withStyles(useStyles)(EditProfileFormic);
