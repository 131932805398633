import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18)
  }
}));
const UserFeedback = props => {
  const classes = useStyles();
  const {
    values: {name, email,mobileNumber,comment},
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched
  } = props;

  

  const change = (name, e) => {
    console.log(name);
    console.log(e);
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="name"
            name="name"
            helperText={touched.name ? errors.name : ""}
            error={touched.name && Boolean(errors.name)}
            label="Name"
            value={name}
            onChange={change.bind(null, "name")}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="email"
            name="email"
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            label="Email"
            fullWidth
            value={email}
            onChange={change.bind(null, "email")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="mobileNumber"
            name="mobileNumber"
            helperText={touched.mobileNumber ? errors.mobileNumber : ""}
            error={touched.mobileNumber && Boolean(errors.mobileNumber)}
            label="Mobile"
            fullWidth
            value={mobileNumber}
            onChange={change.bind(null, "mobileNumber")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline="true"
            rows="2"
            rowsMax="10"
            variant="outlined"
            id="comment"
            name="comment"
            helperText={touched.comment ? errors.comment : ""}
            error={touched.comment && Boolean(errors.comment)}
            label="comment"
            fullWidth
            type="comment"
            value={comment}
            onChange={change.bind(null, "comment")}
          />
        </Grid>
       
     
  
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!isValid}
        >
          Submit
        </Button>
      </Grid>
    </form>
  );
};

export default UserFeedback;
