import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: theme.spacing(1),
    height: theme.spacing(50)
  }
}));

const Dashboard = props => {
  const classes = useStyles();
 
    return (
    <div>
      <Paper className={classes.root}>
        <div>
            <Typography variant="h5" component="h3">
              User Home
            </Typography>
            <Typography variant="p" >
              Your Trade Summary Here
            </Typography>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(useStyles)(Dashboard);
