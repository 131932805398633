import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { GridList, GridListTile, Typography } from "@material-ui/core";
import ProfilePic from "../../images/Avatar1.jpg";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from 'react-router-dom';
import {withStyles} from "@material-ui/core/styles";
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { API_URL, IMAGE_URL }  from '../../Constants'
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
        
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  root:{
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
  },
  marginAll: {
    marginBottom: theme.spacing(2),
  },
  textFieldInput: {  
    width: '300px',
  },
  uploadField:{
    position: 'relative',
    display: 'inline-flex',
    background:'#ffffff'
  },
  btnUpload: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius:25,
    textTransform: "none",
    padding:'9px 35px'
    
  }
  
}));
const ReferFriend = props => {
  const classes = useStyles();
  const history = useHistory();
  const {
    //StateId, CityId
    values: { Name,Phone, Email, Address,ProfilePicPath,CreatedBy},
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue
  } = props;

  // let [states, setStates] = React.useState([])
  // let [cities, setCities] = React.useState([])
  // let [districtid, setDistrictid] = React.useState(0)
  

  // React.useEffect(() => {
  //   axios.get(API_URL+'States')
  //    .then(response => {
  //      console.log(response)
  //      setStates(response.data)
      
  //   });
  //   axios.get(API_URL+'Cities')
  //   .then(response => {
  //     console.log(response)
  //     setCities(response.data)
     
  //  });
  //  }, []);


  const change = (name, e) => {
    // if (name === "StateId") {
    //   setDistrictid(e.target.value);
    // }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  

 


  return (
    <form  onSubmit={handleSubmit} className={classes.root}>
      
             <CustomTextField
             InputProps={{
               classes: {
                 root: classes.textFieldInput
               },
             }}
               variant="outlined"
               id="Name"
               name="Name"
               helperText={touched.Name ? errors.Name : ""}
               error={touched.Name && Boolean(errors.Name)}
               label="Full Name"
               value={Name}
               onChange={change.bind(null, "Name")}
               size="small"
               className={classes.marginAll}
             />
          
         
      
          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="Phone"
            name="Phone" 
            helperText={touched.Phone ? errors.Phone : ""}
            error={touched.Phone && Boolean(errors.Phone)}
            label="Phone Number"
            value={Phone}
            onChange={change.bind(null, "Phone")}
            size="small"
            className={classes.marginAll}
            
          />



        
          <CustomTextField
            InputProps={{
              classes: {
                root: classes.textFieldInput
              },
            }}
            variant="outlined"
            id="Email"
            name="Email"
            helperText={touched.Email ? errors.Email : ""}
            error={touched.Email && Boolean(errors.Email)}
            label="Email (Optional)"
            value={Email}
            onChange={change.bind(null, "Email")}
            size="small"
            className={classes.marginAll}
          />


       
        

         {/* <CustomTextField
          InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
           
            select
            id="StateId"
            label="Select District"
            name="StateId"
            value={StateId}
            onChange={change.bind(null, "StateId")}
            variant="outlined"
            size="small"
            className={classes.marginAll}
          >
            {states.map(option => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.Name}
              </MenuItem>
            ))}
          </CustomTextField>
        
          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
             
            select
            id="CityId"
            label="Select Location"
            name="CityId"
            value={CityId}
            onChange={change.bind(null, "CityId")}
            variant="outlined"
            size="small"
            className={classes.marginAll}
          >
            {(cities.filter(x=>x.StateId === districtid)).map(option => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.Name}
              </MenuItem>
            ))}
          </CustomTextField> */}


          <CustomTextField
            type="hidden"
            value={CreatedBy}
          
          />
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          className={classes.marginAll}
          disabled={!isValid}
          style={{borderRadius:25,textTransform: "none",padding:'9px 25px'}}
        >
         Refer & Save
          </Button>

          <Button
          onClick={()=>history.push('/referred-friend-list')}
          size="small"
          variant="outlined"
          color="primary"
          className={classes.marginAll}
          disabled={!isValid}
          style={{borderRadius:25,textTransform: "none",padding:'9px 25px',marginLeft:'7px'}}
        >
         Referred List
          </Button>
        </div>
       
   
          
        
        
           
       
    </form>
  );
};

export default ReferFriend;
