
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import { API_URL } from '../../Constants';
import { Typography } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Eclair', 262, 16.0),
];

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function ReferredFriendList() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
 
  let [referredFriendList,setReferredFriendList]=React.useState([])
  React.useEffect(()=>{
    setLoading(true)
    let user=JSON.parse(localStorage.getItem('user'))
    axios.get(API_URL+'UserInfoes/ReferredList?userId='+user.Id)
    .then(response => {
      console.log(response)
      setReferredFriendList(response.data)
      setLoading(false)
   });
   },[])

  return (
        loading?<Typography variant="body2">loading</Typography>:
         
      
    <TableContainer component={Paper} style={{marginTop:'10px'}}>
        {
        referredFriendList.length==0? <Typography align="center" color="primary" variant="h6">No Referred Friends</Typography>:
        <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="right">Referred User Name</StyledTableCell>
            <StyledTableCell align="right">Referred User Phone</StyledTableCell>
            <StyledTableCell align="right">Referred At</StyledTableCell>
            <StyledTableCell align="right">ActiveStatus</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {referredFriendList.map((row,i) => (
            <StyledTableRow key={i}>
              <StyledTableCell component="th" scope="row">
                {row.ReferredUserName}
              </StyledTableCell>
              <StyledTableCell align="right">{row.ReferredUserPhone}</StyledTableCell>
              <StyledTableCell align="right">{row.ReferredAt}</StyledTableCell>
              <StyledTableCell align="right">{row.ReferredUserActiveStatus}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
        }
     
    </TableContainer>
  );
}
