import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AddShop from "./AddShop";
import { Paper } from "@material-ui/core";
import axios from 'axios'
import { API_URL } from "../../Constants";
import "react-toastify/dist/ReactToastify.css";
import { toast,ToastContainer } from "react-toastify";


const useStyles = theme => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
   // alignItems: 'center',
    maxWidth:700,
    maxHeight:900,
   
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom:'20px'
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  topLabel:{
     width:'250px',
     height:'40px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
  },
  rightTopHalfCircle:{
    height:'40px',
    width:'20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
   
},
bottomRightTriangle:{
     width:'30px',
     height:'30px',
     background: '#D1152D',
     borderRadius: `90px 0 0px 0`
},

});
  const  AddShopFormic=(props)=> {

    
 const submit =  (data,{resetForm}) => {
   console.log(data)
  let userInfo=JSON.parse(localStorage.getItem('user'));
    let shop=
       {
        ShopTypeId: data.ShopTypeId,
        Name: data.Name,
        LogoPictureId: data.LogoPictureId, // optional
        Phone: userInfo.Phone, 
        CreatedBy: userInfo.Id
        }
    
        axios.post(API_URL+'Shops',shop).then(response=>{
         if(response.data.Meta.Status==1){
          toast.success("Shop added Successfully");
           resetForm({});
         }else{
           toast.error("Something went wrong");
         }
  })
  };

 const validationSchema = Yup.object({
        Name: Yup.string("Enter a name").required("Name is required"),
        ShopTypeId: Yup.string("Select Shop Type").required(
            "Shop Type is required"
          ),
     
  });

 const values = {
    Name: "",ShopTypeId:""
  };

  const { classes } = props;
    

    return (
    
     <Paper className={classes.paper}>

         <div className={classes.flexContainer}>
         <div className={classes.topLabel}>
         <Typography variant="body1"  color="secondary">
          Create New Shop
        </Typography>  </div>
        <div className={classes.rightTopHalfCircle}>  </div>
         </div>
      
      
          <Formik
            render={props => <AddShop {...props} />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
          />
         <div className={classes.flexZone}>
      
        <div className={classes.bottomRightTriangle}>  </div>
         </div>
         <ToastContainer autoClose={2000} />
        </Paper>
    );
  }

export default withStyles(useStyles)(AddShopFormic);

