import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, Route } from "react-router-dom";
import { Grid, Typography, Container} from "@material-ui/core";
import LeftSideBar from "../components/LeftSideBar";
import {useHistory} from 'react-router-dom';

import { grey} from '@material-ui/core/colors';
import NavBar from "../components/Navbar";
import BannerTop from "../images/shop-banner.png"
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  box:{
    position: 'relative'
},
text:{
    position: 'absolute',
    zIndex: 999,
    margin: '0 auto',
    left: 0,
    right: '40%',
    top:'70%',      
    textAlign: 'center',
    fontFfamily: 'Arial,sans-serif',
    color: '#fff',
   
}

}));
const SecondaryLayout = ({ children, ...rest }) => {
  const classes = useStyles();
  const history=useHistory();
  let { guid } = useParams();
  const [isAuthenticated,setIsAuthenticated]=React.useState(false)

  React.useEffect(()=>{
  
   if(localStorage.getItem('user')) setIsAuthenticated(true)
  },[])

const  handleAuthenticate=()=>{
    setIsAuthenticated(false)
  }
 



  let [text,setText]=React.useState('')
 
  
  React.useEffect(()=>{
    console.log(history.location.pathname)
    switch(history.location.pathname){
      case '/add-shop':
       setText('Create New Shop')
       break;
       case '/shop-list':
       setText('Shop List')
       break;
       case '/manage-shop':
        setText('Manage Shop')
        break;
        case '/my-ad':
          setText('My Advertisement')
         break;
          case '/my-profile':
            setText('My Profile')
            break;
            case '/edit-profile/'+guid:
              setText('Edit Profile')
              break;
              case '/refer-friend':
            setText('Refer Your Friend')
            break;
            case '/referred-friend-list':
            setText('Referred List')
            break;
            case '/my-connections':
            setText('My Connections')
            break;
            case '/connect-list':
            setText('All Connections')
            break;
            case '/reward-point':
              setText('Rewards Point')
              break;
           
       default:
         setText('Shop List');
         break;
   }
  })
  return (
    <div style={{backgroundColor:'#F2F2F2',width: '100%', height: '100%',position: 'absolute', top: 0, left: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'}}>
      <Grid container >
          <Grid item> <NavBar authenticate={handleAuthenticate} isAuthenticated={isAuthenticated} /></Grid>
      </Grid>
      <Grid className={classes.box} container style={{paddingTop: '96px'}}  >
        <img src={BannerTop} width="100%" alt="banner-shop"/>
        <div className={classes.text}>
            <Typography variant="h6">{text}</Typography>
        </div>
      </Grid>

      <Container maxWidth="xl" style={{backgroundColor:'#F2F2F2'}}>
    <Grid container spacing={1}>
      <Grid item xl={3} lg={3} md={3} sm={3}>
      <LeftSideBar isAuthenticated={isAuthenticated}/>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <div className="main">{children}</div>
          </Grid>
        </Grid>
      </Grid>

      <Grid  item xl={3} lg={3} md={3} sm={3}>
       
            <Typography  variant="body2">© 2020 - SundarbanX</Typography>
        
      </Grid>
    </Grid>
    </Container>
    </div>
  );
};

const SecondaryLayoutRoute = ({ component: Component, ...rest }) => {
  const history=useHistory();
  return (
    <Route
      {...rest}
      render={matchProps => (
        (localStorage.getItem('user') && localStorage.getItem('userToken'))?
        <SecondaryLayout>
          <Component {...matchProps} />
        </SecondaryLayout>:
        <Redirect to={{ pathname: '/login', state: { redirectPath: history.location.pathname }}} />
      )}
    />
  );
};

export default SecondaryLayoutRoute;
