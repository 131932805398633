import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {withStyles} from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";


const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
        
      },
    },
  },
})(TextField);



const useStyles = makeStyles(theme => ({
  root:{
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
  },
  marginAll: {
    marginBottom: theme.spacing(3),
  },
  textFieldInput: {  
    width: '300px',
  },
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "30%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
  
}));
const LoginForm = props => {
  const classes = useStyles();
  const history = useHistory();
  const {
    values: { LoginId, Password },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    loading
  } = props;

  const change = (name, e) => {
    console.log(e)
    console.log(name)
    e.persist();
    handleChange(e);
    
    setFieldTouched(name, true, false);
  };
  return (
    <form  onSubmit={handleSubmit} className={classes.root}>
          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="LoginId"
            name="LoginId"
            helperText={touched.LoginId ? errors.LoginId : ""}
            error={touched.LoginId && Boolean(errors.LoginId)}
            label="Phone Number"
            value={LoginId}
            onChange={change.bind(null, "LoginId")}
            size="small"
            className={classes.marginAll}
            
          />
  
          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="Password"
            name="Password"
            helperText={touched.Password ? errors.Password : ""}
            error={touched.Password && Boolean(errors.Password)}
            label="Password"
            type="Password"
            value={Password}
            onChange={change.bind(null, "Password")}
            size="small"
            
            className={classes.marginAll}
          />
  
       
       
          <div className="flex flex-wrap items-center mb-4">
                      <div className={classes.wrapper}>
                      <Button
                            type="submit"
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.marginAll}
                            disabled={!isValid || loading}
                            style={{borderRadius:25,textTransform: "none",padding:'9px 50px'}}
                           
                          >
                            Sign In
                      </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    
                    </div>
              
        
        
            <Link style={{ cursor: 'pointer',marginBottom:'15px' }} onClick={() => history.push('/recovery')} variant="body2">
              Forgot password?
             </Link>
       
    </form>
  );
};

export default LoginForm;