

import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import UserFeedback from "./UserFeedback";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        SundarbanX
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});
class UserFeedbackFormic extends React.Component {
  
  submit = data => {
    console.log(data);
  };
  validationSchema = Yup.object({
    name: Yup.string("Enter a name").required("Name is required"),
    email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
    mobileNumber: Yup.string("Enter your Mobile Number").required(
      "Mobile Number is required"
    ),
    comment: Yup.string("Enter your Comment")
      .required("Comment is required"),

  });

  values = {
    name: "",
    email: "",
    mobileNumber: "",
    comment: ""
  };

  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography variant="h5" component="h3">
          User Feedback
        </Typography>
        <div className={classes.paper}>
          <Formik
            render={props => <UserFeedback {...props} />}
            initialValues={this.values}
            validationSchema={this.validationSchema}
            onSubmit={this.submit}
          />
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
export default withStyles(useStyles)(UserFeedbackFormic);
