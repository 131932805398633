import axios from "axios";

function TokenInterceptorService(){
    
axios.interceptors.request.use(async(config)=>{
    
    
    const API_URL = "https://resource.sundarbanx.com/api/";
    let userToken=localStorage.getItem('userToken');
    if(userToken){
        config.headers['Authorization']='Bearer '+userToken;
        return config;
    }
    let accessToken=localStorage.getItem('accessToken');
    if(accessToken){
        config.headers['Authorization']='Bearer '+accessToken;
    }else{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization':'Bearer 159-' },//hard coded token
            body: JSON.stringify({ LoginId:'01000000001',Password:'1' })//hard coded system user id and password
        };
        fetch(API_URL+'Token', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.Meta.Status==1){
                    console.log('From Interceptor');
                    localStorage.setItem("accessToken", data.Data);
                    config.headers['Authorization']='Bearer '+localStorage.getItem('accessToken');
                }
               
                
            });
    
    }
    return config;    
},error=>{ return Promise.reject(error)});

// axios.interceptors.response.use((response)=>{  return response}
// ,error=>{ return Promise.reject(error)});

}


export default TokenInterceptorService;