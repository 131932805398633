import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {ListSubheader} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { grey} from '@material-ui/core/colors';
import {useHistory} from 'react-router-dom';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import VolumeDownOutlinedIcon from '@material-ui/icons/VolumeDownOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import ShopOutlinedIcon from '@material-ui/icons/ShopOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';


const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    maxWidth: 360,
    backgroundColor: grey[100],
    margin:0,
    position:'sticky'
  }

}));

const LeftSideBar=({isAuthenticated})=> {
  const classes = useStyles();
  const history=useHistory();
  const [open, setOpen] = React.useState(true);
  let [sidebarPosition,setSidebarPosition]=React.useState('static');
  let [top,setTop]=React.useState(300);
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if(window.pageYOffset > 300){
        setSidebarPosition('sticky');
        setTop(75);
      }
    });
  })
  const handleClick = () => {
    setOpen(!open);
  };
  const handleMyAd=()=>{
    if(!localStorage.getItem('user')){
      history.push('/login',{redirectPath:'/my-ad'})
  }else{
    history.push('/my-ad')
  }
  }
  return (
    <div style={{position:sidebarPosition,top:top}}>
      {
        isAuthenticated &&
        <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            
          </ListSubheader>
        }
        className={classes.root}
      >
        {/* <ListItem color="primary"  button onClick={()=>{history.push('/')}}>
         
         <DashboardIcon/>&nbsp; <ListItemText primary="Dashboard" />
        </ListItem> */}
       
         
        {/* <ListItem button>
         <HelpOutlineOutlinedIcon/>&nbsp; <ListItemText primary="About" />
        </ListItem>
        <ListItem button onClick={handleClick}>
          
        <NotificationsActiveOutlinedIcon/>&nbsp;  <ListItemText primary="Notifications" />
        </ListItem>
        <ListItem color="primary"  button onClick={()=>{history.push('/shop-list')}}>
         
        <ShoppingCartOutlinedIcon/>&nbsp; <ListItemText primary="Shops" />
       </ListItem>
       */}
         <ListItem button  onClick={handleMyAd}>
       <VolumeDownOutlinedIcon/>&nbsp;   <ListItemText primary="My Advertisement" />
       </ListItem>
       <ListItem button onClick={()=>{history.push('/reward-point')}}>          
           <AccountBalanceWalletOutlinedIcon/>&nbsp;  <ListItemText primary="Rewards Point" />
       </ListItem>
       {/* <ListItem button>
        
       <RateReviewOutlinedIcon/>&nbsp;  <ListItemText primary="Customer Reviews" />
      </ListItem> */}
      <ListItem button onClick={()=>{history.push('/add-shop')}}>
        
      <AddBoxOutlinedIcon/>&nbsp;  <ListItemText primary="Create Shop" />
      </ListItem>
      <ListItem color="primary"  button onClick={()=>{history.push('/manage-shop')}}>
         
      <ShopOutlinedIcon/>&nbsp;  <ListItemText primary="Manage Shop" />
       </ListItem>
       {/* <ListItem color="primary"  button onClick={()=>{history.push('/shop')}}>
         
       <StoreOutlinedIcon/>&nbsp;  <ListItemText primary="My Shop" />
       </ListItem> */}
       <ListItem color="primary"  button onClick={()=>{history.push('/my-profile')}}>
         
       <AccountBoxOutlinedIcon/>&nbsp;   <ListItemText primary="My Profile" />
       </ListItem>
       <ListItem color="primary"  button onClick={()=>{history.push('/referred-friend-list')}}>
         
       <RecentActorsOutlinedIcon/>&nbsp;   <ListItemText primary="Referred List" />
       </ListItem>
       <ListItem color="primary"  button onClick={()=>{history.push('/my-connections')}}>
         
       <SupervisedUserCircleOutlinedIcon/>&nbsp;   <ListItemText primary="My Network" />
       </ListItem>

       <ListItem color="primary"  button onClick={()=>{history.push('/connect-list')}}>
         
         <GroupAddOutlinedIcon/>&nbsp;   <ListItemText primary="Connect People" />
         </ListItem>
       
      </List>
      }
 
 
    </div>
   );
}
export default LeftSideBar;