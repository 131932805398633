import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Avatar, Button, Grid, Paper, TextField } from "@material-ui/core";
import axios from 'axios'
import { API_URL } from "../../Constants";
import "react-toastify/dist/ReactToastify.css";
import { toast,ToastContainer } from "react-toastify";
import { AccountCircle } from "@material-ui/icons";
import {useHistory} from 'react-router-dom'

const CustomTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 25,
          
        },
      },
    },
  })(TextField);
  
const useStyles = theme => ({
  paper: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    background:'#ffffff'
   
  },
  textFieldInput: {  
    width: '400px',
  },
  root:{
    position: 'relative',
    display: 'inline-flex',
    
  },
 
});
  const  AddReply=(props)=> {
    const history=useHistory()
  const {commentId}=props
    let [loggedInUser,setLoggedInUser]=React.useState({})
  

 const submit =  (data,{resetForm}) => {
  let userInfo=JSON.parse(localStorage.getItem('user'));
  setLoggedInUser(userInfo)
    let comment=
       {
        CommentId: commentId,
        Comment: data.Comment,
        CreatedAt: new Date(), 
        CreatedBy: userInfo.Id
        }
    
        axios.post(API_URL+'CommentReplies',comment).then(response=>{
         if(response.data.Meta.Status==1){
        console.log(response.data.Data)
           resetForm({});
         }else{
           toast.error("Something went wrong");
         }
  })
  };

 const validationSchema = Yup.object({
    Comment: Yup.string("Post a Reply").required("Comment is required"),
       
     
  });

 const values = {
    Comment: ""
    
  };

  const { classes } = props;


    return (
    
    <div className={classes.paper}>

          <Formik
            render={props => (  <form  onSubmit={props.handleSubmit}  className={classes.root}>
             <div style={{display:'flex',justifyContent:'flex-start', width: '50%',maxWidth:150}}>
         <div>
        <Avatar alt={loggedInUser.Name} src={""} />
        </div>
        <div style={{ paddingLeft:'15px'}}>
          <CustomTextField
             InputProps={{
               classes: {
                 root: classes.textFieldInput
               },
             }}
             onKeyDown={(e) => {if (e.keyCode === 13) {
              props.handleSubmit()
          }}}
            variant="outlined"
            id="Comment"
            name="Comment"
            placeholder="Write Here"
            helperText="Press Enter to post"
          //  helperText={props.touched.Comment1 ? props.errors.Comment1 : ""}
            error={props.touched.Comment && Boolean(props.errors.Comment)}
          //  label="Comment1"
            value={props.values.Comment}
            onChange={
             
                (e,name)=>{ 
                  console.log(e)
                  if(localStorage.getItem('user')==null){
                    history.push('/login')
                  }
                e.persist();
                props.handleChange(e);
                props.setFieldTouched(name, true, false);
                if (e.key === 'Enter') {
                 props.handleSubmit(e);
                }
            }}
            size="small"
            fullWidth
            multiline
          />
 
          <button type="submit" style={{visibility:"hidden"}}  >    </button>
           
          </div>
    </div>
             
                </form>)}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
          />
     
      

       </div>
       
    );
  }

export default withStyles(useStyles)(AddReply);

