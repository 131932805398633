import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup"
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PasswordRecovery from "./PasswordRecovery";
import x from "../../images/x.png";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import VerificationCode from "./VerificationCode";
import ResetPassword from "./ResetPassword";
import axios from "axios"
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const useStyles = theme => ({

  paper: {
    margin: 'auto',
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth:450,
    maxHeight:500,
    borderRadius:15,
    
  },
  avatar: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(7),
    height: theme.spacing(8)
  },
 
  circle:{
    height:'100px',
    width:'100px',
    borderRadius: '100px',
    background:'#ffffff',display:'flex',
    justifyContent:'center',
    position: 'relative',
    top: '-50px'
}
 
});

const PasswordRecoveryFormic =props=> {
  let [isRecovery,setIsRecovery]=React.useState(true)
  let [isVerify,setIsVerify]=React.useState(false)
  let [isReset,setIsReset]=React.useState(false)
  let [code,setCode]=React.useState("")
  let [guid,setGuid]=React.useState("")


  const submit1 = data => {
    console.log(data);
    axios.get(API_URL+'UserInfoes/OTPForForgetPass?mobile='+data.mobileNumber+'&userType=3')
    .then(response=>{
      console.log(response);
      if(response.data.Meta.Status==1){
        setIsRecovery(false)
        setIsVerify(true)
        setCode(response.data.Data.Code)
        setGuid(response.data.Data.userGUID)
      }else{
        toast.error(response.data.Meta.Message+','+response.data.Data.SMSStatus)
      }
      
    })

  };
const validationSchema1 = Yup.object({
  
    mobileNumber: Yup.string("Enter your Mobile Number")
    .required("Mobile Number is required")
    .matches(
      /^(01[3456789])(\d{8})$/,
      "Please Enter valid Bangladeshi Phone Number"
    ),
   })

  const values1 = { mobileNumber: "" };


 const submit2 = data => {
    if(data.verificationPin==code){
      setIsVerify(false)
      setIsReset(true)
    }else{
      toast.error("Incorrect Pin");
    }

  };
const validationSchema2 = Yup.object({
  
  verificationPin: Yup.string("Enter your Verification Pin")
    .required("Verification Pin is required")
   })

  const values2 = { verificationPin: "" };


  const submit3 = data => {
    const model={
      Password:data.newPassword,
      GUID:guid
    }

    axios.put(API_URL+'UserInfoes/UpdatePassword',model).then(response=>{
      if(response.data.Meta.Status==1){
        toast.success(response.data.Meta.Message);
      }else
      {
        toast.error("Something Went Wrong");
      }
    })
  };
const validationSchema3 = Yup.object({
  
    newPassword: Yup.string("")
    .min(4, "New Password must contain at least 4 characters")
    .required("Enter your New Password"),
    confirmPassword: Yup.string("Enter your password")
    .required("Confirm your password")
    .oneOf([Yup.ref("newPassword")], "Password does not match")
   })

  const values3 = { newPassword: "", confirmPassword: "" };


    const { classes } = props;

   return (
    <React.Fragment >
   
   
      <Paper className={classes.paper}>
        <div className={classes.circle}>
        <img className={classes.avatar} src={x}/>
        </div>
     
         {isRecovery?
           <Formik
             render={props => <PasswordRecovery {...props} />}
             initialValues={values1}
             validationSchema={validationSchema1}
             onSubmit={submit1}
           />:null
         }
       {isVerify?
          <Formik
             render={props => <VerificationCode {...props} />}
             initialValues={values2}
             validationSchema={validationSchema2}
             onSubmit={submit2}
           />:null
           
           }
       {isReset? 
          <Formik
             render={props => <ResetPassword {...props} />}
             initialValues={values3}
             validationSchema={validationSchema3}
             onSubmit={submit3}
           />:null
           
           }
      
    
        </Paper>
        <ToastContainer autoClose={4000} />
    
    </React.Fragment>
   );
 }
export default withStyles(useStyles)(PasswordRecoveryFormic)