import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles, Grid, Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL, BASEIMAGE } from "../../Constants";
import LogoShop from '../../images/logo-shop1.png';
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonCard from "../../layouts/SkeletonCard";
import ConfirmDialog from "../utilities/ConfirmDialog";
import "react-toastify/dist/ReactToastify.css";
import { toast,ToastContainer } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(50),
    display:'flex',
    justifyContent:'space-between',
    
  },
  triangleBottomRight:{
    width:0,
    height:0,
    borderRight:'110px solid #D1152C',
    borderTop:'35px solid transparent',
    borderLeft:'300px solid transparent',
    borderBottom:'50px solid #D1152C',
    
},
flexContainer:{
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center'
},
spec: {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
 
},
large:{
  height:140,
  width:140
}
}));

const ManageShop = props => {
  const classes = useStyles();
  const history = useHistory();
  let [shops,setShops]=React.useState([])
  const [confirmDialog, setConfirmDialog] = React.useState({ isOpen: false, title: '', subTitle: '' })

  React.useEffect(()=>{
     getShopsBySellerId(JSON.parse(localStorage.getItem('user')).Id);
     console.log(shops)
  },[])

  const getShopsBySellerId=id=>{
    axios.get(API_URL+'Shops/BySeller?sellerId='+id).then(res=>{
      setShops(res.data)
  })
  }

  const onDelete = guid => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })
   axios.delete(API_URL+'Shops?deletedBy='+JSON.parse(localStorage.getItem('user')).Id+'&guid='+guid).then(res=>{
      toast.success('Shop Deleted Successfully');
      getShopsBySellerId(JSON.parse(localStorage.getItem('user')).Id);
   },error=>{
    toast.error('Shop Deletion Failed !!!')
   })
}

  const [hasMore,setHasMore]=React.useState(true)
 let[page,setPage]=React.useState(0);
 const fetchMoreData=()=>{
   page++;
   setPage(page);
      axios.get(API_URL+'Shops/BySeller?sellerId='+(JSON.parse(localStorage.getItem('user'))).Id+'&pageIndex='+page)
      .then(response => {
        if(response.data.length<10) setHasMore(false);
        setShops(shops.concat(response.data))
       
     });
  }
 
    return (
      <InfiniteScroll
      dataLength={shops.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<SkeletonCard/>}
      
      scrollableTarget="scrollableDiv"
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {
         shops?.map((shop,index)=>(
          <Paper className={classes.root} key={index}>
          
          <div style={{alignSelf:'center'}}> <Paper> <Avatar src={shop.LogoPictureId==0?(BASEIMAGE+'/Upload/Default/sbx-shop-default-logo.png'): (BASEIMAGE+'/Upload/'+shop.LogoPath)} className={classes.large}/></Paper></div>
          <div>
          <div className={classes.spec}>
                   <Typography  variant="subtitle2" color="primary">
                      Name:
                    </Typography>
                    <Typography variant="subtitle2" color="textPrimary">
                   {shop.Name}
                    </Typography>
                    
                   </div>
        
          <Typography variant="subtitle2">Type:{shop.UserTypeTitle}</Typography>
         <Typography variant="subtitle2">Contact:{shop.Phone}</Typography>
         <Typography variant="subtitle2">Website:{shop.Website}</Typography>
         <Typography variant="subtitle2">Facebook:{shop.FBPageLink}</Typography>
         <Typography variant="subtitle2">Address:{shop.Address}</Typography>
          </div>
          <div className={classes.flexContainer}>    
          <Button type="submit" color="secondary"  style={{ borderRadius: 25,textTransform: "none" ,width:100,marginBottom:'5px',color:'#D1152C'}} variant="contained">Details</Button>
          <Button type="submit" onClick={()=>{history.push('/edit-shop/'+shop.GUID)}} color="secondary"  style={{ borderRadius: 25,textTransform: "none" ,width:100,marginBottom:'5px',color:'#D1152C'}} variant="contained">Edit</Button>
          <Button onClick={() => {
                                  setConfirmDialog({
                                      isOpen: true,
                                      title: 'Are you sure to delete this Shop?',
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => { onDelete(shop.GUID) }
                                  })
                              }}
                              type="submit" color="primary"  style={{ borderRadius: 25,textTransform: "none" ,width:100}} variant="contained">Delete</Button>      
          </div>
       </Paper>
         ))
      }
      <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
             <ToastContainer autoClose={2000} />
     </InfiniteScroll>
     
  );
};

export default withStyles(useStyles)(ManageShop);
