import React from 'react';  
import { Route } from 'react-router-dom';  
import BgImage from '../images/bg.png';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const LoginLayout = ({ children }) => (                         
    <div style={{backgroundImage: `linear-gradient( #50A68480, #812D2D), url(${BgImage})`,width: '100%', height: '100%',position: 'absolute', top: 0, left: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
    }}> 
   
  
    {children} 
   
                                           
    </div>  
  );  
  
  const LoginLayoutRoute = ({component: Component, ...rest}) => {  
    return (  
      <Route {...rest} render={matchProps => (  
        <LoginLayout>  
            <Component {...matchProps} />  
        </LoginLayout>  
      )} />  
    )  
  };  
  
export default LoginLayoutRoute; 