import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Avatar, Button, Grid, Paper, TextField } from "@material-ui/core";
import axios from 'axios'
import { API_URL } from "../../Constants";
import "react-toastify/dist/ReactToastify.css";
import { toast,ToastContainer } from "react-toastify";
import { AccountCircle } from "@material-ui/icons";
import AddReply from "./AddReply";
import SingleComment from "./SingleComment";
import SingleReply from "./SingleReply";
import {useHistory} from 'react-router-dom'

const CustomTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 25,
          
        },
      },
    },
  })(TextField);
  
const useStyles = theme => ({
  paper: {
    marginBottom: 10,
   // marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
   // alignItems: 'center',
   background:'#ffffff'
   
  },
  textFieldInput: {  
    width: '400px',
  },
  root:{
    position: 'relative',
    display: 'inline-flex',
    
  },
 
});
  const  AddCommentFormik=(props)=> {
    const history=useHistory()
  const {productId}=props
    let [loggedInUser,setLoggedInUser]=React.useState({})
    let [commentWitnReplyList,setCommentWitnReplyList]=React.useState([])
React.useEffect(()=>{
  axios.get(API_URL+'Comments/WithReply?productId='+productId).then(response=>{
    setCommentWitnReplyList(response.data)
})
},[])

 const submit =  (data,{resetForm}) => {
   console.log(data)
   
  let userInfo=JSON.parse(localStorage.getItem('user'));
  setLoggedInUser(userInfo)
    let product=
       {
        ProductId: productId,
        Comment1: data.Comment1,
        CreatedAt: new Date(), 
        CreatedBy: userInfo.Id
        }
    
        axios.post(API_URL+'Comments',product).then(response=>{
         if(response.data.Meta.Status==1){
           //get all reply for realtime
           axios.get(API_URL+'Comments/WithReply?productId='+productId).then(response=>{
            setCommentWitnReplyList(response.data)
        })
          
           resetForm({});
         }else{
           toast.error("Something went wrong");
         }
  })
  };

 const validationSchema = Yup.object({
    Comment1: Yup.string("Post a Comment").required("Name is required"),
       
     
  });

 const values = {
    Comment1: ""
    
  };

  const { classes } = props;
 
 

    return (
    
    <div className={classes.paper}>

          <Formik
            render={props => (  <form  onSubmit={props.handleSubmit}  className={classes.root}>
             <div style={{display:'flex',justifyContent:'flex-start', width: '50%',maxWidth:150}}>
         <div style={{paddingTop:'10px',paddingLeft:'15px'}}>
        <Avatar alt={loggedInUser.Name} src={""} />
        </div>
        <div style={{ paddingLeft:'15px',paddingTop:'10px'}}>
          <CustomTextField
             InputProps={{
               classes: {
                 root: classes.textFieldInput
               },
             }}
             onKeyDown={(e) => {if (e.keyCode === 13) {
              props.handleSubmit()
          }}}
            variant="outlined"
            id="Comment1"
            name="Comment1"
            placeholder="Write Here"
            helperText="Press Enter to post"
          //  helperText={props.touched.Comment1 ? props.errors.Comment1 : ""}
            error={props.touched.Comment1 && Boolean(props.errors.Comment1)}
          //  label="Comment1"
            value={props.values.Comment1}
            onChange={
             
                (e,name)=>{ 
                  console.log(e);
                  if(localStorage.getItem('user')==null){
                    history.push('/login')
                  }
                e.persist();
                props.handleChange(e);
                props.setFieldTouched(name, true, false);
                if (e.key === 'Enter') {
                 props.handleSubmit(e);
                }
            }}
            size="small"
            fullWidth
            multiline
          />
 
          <button type="submit" style={{visibility:"hidden"}}  >    </button>
           
          </div>
    </div>
             
                </form>)}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
          />
          
        {
          commentWitnReplyList.length>0? commentWitnReplyList.map((x,i)=>(         
          <SingleComment key={i} comment={x} noOfReplies={ x.CommentReplyList.length}/>         
          )) :null

        }
 

       </div>
       
    );
  }

export default withStyles(useStyles)(AddCommentFormik);

