import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import {withStyles} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";


const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
        
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  root:{
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
  },
  marginAll: {
    marginBottom: theme.spacing(3),
  },
  textFieldInput: {  
    width: '300px',
  },
 
  
}));
const PasswordRecovery = props => {
  const classes = useStyles();
  const history = useHistory();
  const {
    values: { mobileNumber, password },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched
  } = props;

  const change = (name, e) => {
    console.log(e)
    console.log(name)
    e.persist();
    handleChange(e);
    
    setFieldTouched(name, true, false);
  };
  return (
    <form  onSubmit={handleSubmit} className={classes.root}>

         <Typography style={{marginRight:'160px',marginBottom:'6px'}}>Recover Password</Typography>
          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="mobileNumber"
            name="mobileNumber"
            helperText={touched.mobileNumber ? errors.mobileNumber : ""}
            error={touched.mobileNumber && Boolean(errors.mobileNumber)}
            label="Phone Number / Email"
            value={mobileNumber}
            onChange={change.bind(null, "mobileNumber")}
            size="small"
            className={classes.marginAll}
            
          />
  
      
       
        <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          className={classes.marginAll}
          disabled={!isValid}
          style={{borderRadius:25,textTransform: "none",padding:'9px 35px'}}
        >
          Send Recovery Pin
          </Button>
        
        
        
            <Link style={{ cursor: 'pointer',marginBottom:'15px' }} onClick={() => history.push('/login')} variant="body2">
              Try to Log In
             </Link>
       
    </form>
  );
};

export default PasswordRecovery;