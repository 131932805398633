import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {  useParams } from "react-router-dom";
import { Grid, Typography, Container, Avatar, Paper} from "@material-ui/core";
import LeftSideBar from "../components/LeftSideBar";
import {useHistory} from 'react-router-dom';
import NavBar from "../components/Navbar";
import axios from 'axios'
import { API_URL, IMAGE_URL } from "../Constants";
import BannerImg from '../images/my-banner.png'
import ShopRightSidebar from "../components/Shops/ShopRightSidebar";
import PublicProfile from "../components/my-profile/PublicProfile";
import { Shop } from "@material-ui/icons";
import ShopDetail from "../components/Shops/ShopDetail";


const useStyles = makeStyles(theme => ({
  box:{
    position: 'relative'
},
text:{
    position: 'absolute',
    zIndex: 999,
    margin: '0 auto',
    left: 0,
    right: '40%',
    top:'70%',      
    textAlign: 'center',
    fontFamily: 'Arial,sans-serif',
    color: '#fff',
   
}

}));
export default function ShopLayout() {
  let [shop,setShop]=React.useState({})
  let { link } = useParams();
  const classes = useStyles();
  const history=useHistory();
  const [isAuthenticated,setIsAuthenticated]=React.useState(false)


  React.useEffect(()=>{
    if(localStorage.getItem('user')) setIsAuthenticated(true);  
      axios.get(API_URL+'Shops/Details?link='+link).then(res=>{
        console.log(res.data.Data)
        setShop(res.data.Data)
      })
  },[])

const  handleAuthenticate=()=>{
    setIsAuthenticated(false)
  }

  return (
    <div style={{backgroundColor:'#F2F2F2',width: '100%', height: '100%',position: 'absolute', top: 0, left: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'}}>
      <Grid container>
          <Grid item> <NavBar authenticate={handleAuthenticate} isAuthenticated={isAuthenticated} /></Grid>
      </Grid>
      <Container maxWidth="xl" style={{paddingTop: '75px',backgroundColor:'#F2F2F2'}}>
      <Grid container spacing={3} >
          <Grid item md={3} lg={3} style={{margin:'auto',paddingLeft:'70px'}}> 
          <Avatar alt= {shop?.Name} style={{width:220,height:220,marginBottom:'10px'}} src={IMAGE_URL+shop?.LogoPicturePath} />
  <Paper style={{width:200,height:35,borderRadius:25}} elevation={3} ><Typography align="center" color="primary" variant="h6">{shop?.Name}</Typography></Paper>
          </Grid>
          <Grid item md={9} lg={9}> 
              <img width="100%" height="350" src={shop?.SignboardPicturePath==='/Banner/1200/'?BannerImg: IMAGE_URL+shop?.SignboardPicturePath}/>
          </Grid>
      </Grid>
      </Container>

      <Container maxWidth="xl" style={{backgroundColor:'#F2F2F2'}}>
    <Grid container spacing={1}>
      <Grid item xl={3} lg={3} md={3} sm={3}>
        <LeftSideBar isAuthenticated={isAuthenticated}/>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <div className="main"><ShopDetail shopId={shop?.Id}/></div>
          </Grid>
        </Grid>
      </Grid>

      <Grid  item xl={3} lg={3} md={3} sm={3}>
       
            <ShopRightSidebar shopId={shop.Id}/>
        
      </Grid>
    </Grid>
    </Container>
    </div>
  );
};
