import React from "react";
import {Link} from 'react-router-dom';
import {
  withStyles,
  
} from "@material-ui/core";
import UserFeaturedProducts from "./UserFeaturedProducts";
import UserTopPick from "./UserTopPick";

function UserRightSidebar({userId}) {
  
  return (
    <div style={{marginTop:'10px'}}>  
         <UserTopPick userId={userId}/>
        
         <UserFeaturedProducts userId={userId}/>
         <Link style={{color:'#D1152C'}}  >  About Us | </Link>
         <Link style={{color:'#D1152C'}}  >  Contact Us | </Link>
         <Link style={{color:'#D1152C'}}  >  Privacy Policy | </Link>
         <Link style={{color:'#D1152C'}}  >  Terms & Conditions </Link>
        
    </div>
  );
}



export default UserRightSidebar;
