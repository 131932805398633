import React from "react";
import NavBar from "./components/Navbar";
import MiddlePane from "./components/MiddlePane";
import LoginFormFormic from "./components/auth/LoginFormFormic";
import PasswordRecoveryFormic from "./components/auth/PasswordRecoveryFormic";
import { Route, Switch, withRouter } from "react-router-dom";
import ShopList from "./components/Shops/ShopList";
import AddShopFormic from "./components/Shops/AddShopFormic";
import Dashboard from "./components/dashboard/Dashboard";
import MyAdList from "./components/AD/MyAdList";
import ReferFriendFormic from "./components/friends/ReferFriendFormic";
import ReferredFriendList from "./components/friends/ReferredFriendList";
import MyProfile from "./components/my-profile/MyProfile";
import EditProfileFormic from "./components/my-profile/EditProfileFormic";
import UserFeedbackFormic from "./components/feedback/UserFeedbackFormic";
import AdDetail from "./components/AD/AdDetail";
import LoginLayoutRoute from "./layouts/LoginLayout";
import HomepageLayoutRoute from "./layouts/HomepageLayout";
import SecondaryLayoutRoute from "./layouts/SecondaryLayout";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import RegisterByMobileFormic from "./components/auth/RegisterByMobileFormic";
import DetailsPageLayoutRoute from "./layouts/DetailsPageLayout";
import ManageShop from "./components/Shops/ManageShop";
import EditShopFormic from "./components/Shops/EditShopFormic";
import ShopLayoutRoute from "./layouts/ShopLayout";
import ShopDetail from "./components/Shops/ShopDetail";
import MyShopDetail from "./components/Shops/MyShopDetail";
import MyConnections from "./components/People/MyConnections"
import ConnectList from "./components/People/ConnectList";
import PublicProfile from "./components/my-profile/PublicProfile";
import ShopLayout from "./layouts/ShopLayout";
import RewardsPoint from "./components/Rewards/RewardsPoint";
import ProfileLayout from "./layouts/ProfileLayout";
import ProfileRoute from "./components/my-profile/ProfileRoute";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main:'#D1152C'
    },
    secondary: {
      main:'#ffffff'
    }
  }
});
const App = withRouter(({ location }) => {
  
  return (
    <MuiThemeProvider theme={customTheme}>
   <React.Fragment>
      <Switch>


    
      <Route path='/profile' component={ProfileRoute} />

       {/* <Route exact path="/profile/:link" component={ProfileLayout} />*/}
       <Route  path="/shop/:link" component={ShopLayout} /> 
        <SecondaryLayoutRoute          
          path="/user-feedback"
          component={UserFeedbackFormic}
        />
        <SecondaryLayoutRoute
          path="/edit-profile/:guid"
          component={EditProfileFormic}
        />
        <SecondaryLayoutRoute path="/my-profile" component={MyProfile} />
       
        <SecondaryLayoutRoute
          path="/referred-friend-list"
          component={ReferredFriendList}
        />
        
        <SecondaryLayoutRoute  path="/connect-list" component={ConnectList} />
        <SecondaryLayoutRoute  path="/my-connections" component={MyConnections} />
        <SecondaryLayoutRoute  path="/refer-friend" component={ReferFriendFormic} />
      
        <SecondaryLayoutRoute path="/reward-point" component={RewardsPoint} />
        <SecondaryLayoutRoute path="/my-ad" component={MyAdList} />
        <SecondaryLayoutRoute path="/dashboard" component={Dashboard} />
        <SecondaryLayoutRoute path="/add-shop" component={AddShopFormic} />
        <SecondaryLayoutRoute path="/edit-shop/:guid" component={EditShopFormic} />

        {/* <SecondaryLayoutRoute path="/profile/:link" component={ProfileLayout} /> */}

        <SecondaryLayoutRoute path="/shop-list" component={ShopList} />
        <SecondaryLayoutRoute path="/manage-shop" component={ManageShop} />
        <LoginLayoutRoute path="/login" component={LoginFormFormic} />
        <LoginLayoutRoute path="/create-account" component={RegisterByMobileFormic} />
        <LoginLayoutRoute path="/recovery" component={PasswordRecoveryFormic} />
        {/* <ShopLayoutRoute path="/shop/:link" component={ShopDetail} />
        <ShopLayoutRoute path="/profile/:link" component={PublicProfile} />
        <ShopLayoutRoute path="/my-shop" component={MyShopDetail}/> */}
        <DetailsPageLayoutRoute path="/:guid" component={AdDetail}/>
      
        {/* <Route
  path="/profile/:link"
  render={props => {
    const {
      match: {
        params: { link }
      }
    } = props;
    console.log('link from app.js',link)
    return (
    
      <ProfileLayout
        key={`link=${link}`}
        {...props}
      />
    );
  }}
/>; */}

        <HomepageLayoutRoute path="/" component={MiddlePane} />
      </Switch>
    </React.Fragment>
   </MuiThemeProvider>
  
  );
});

export default App;
