

import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { GridList ,GridListTile, Typography, Select, Avatar} from "@material-ui/core";
import { IMAGE_URL, API_URL, Base_Image_URL, BASEIMAGE } from "../../Constants";
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";
import { toast,ToastContainer } from "react-toastify";
import SelectInput from "@material-ui/core/Select/SelectInput";
import LogoShop from '../../images/logo-shop1.png';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
        
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  root:{
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  textFieldInput: {  
    width: '300px',
  },
  uploadField:{
    position: 'relative',
    display: 'inline-flex',
    background:'#ffffff'
  },
  btnUpload: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius:25,
    textTransform: "none",
    padding:'9px 35px'
    
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin:'10px 0'
  },
  
}));
const AddShop = props => {
  const classes = useStyles();
  const {
    values: {Name, ShopTypeId,LogoPictureId},
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue
  } = props;

 
  let [shopTypes, setShopTypes] = React.useState([])
  
  let [logoFileName, setLogoFileName] = React.useState("")

  React.useEffect(() => {
    axios.get(API_URL+'ShopTypes')
    .then(response => {
      console.log(response)
      setShopTypes(response.data)
     
   });
  

   }, []);

 
  const change = (name, e) => {
   
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };


  const hiddenFileInput = React.useRef(null);
  
  const handleUploadLogo = event => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = event => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    let reader=new FileReader();
    reader.readAsDataURL(fileUploaded);

    reader.onloadend=e=>{
      let imageCode = reader.result.toString().split(',')[1];
      console.log(imageCode);          
       axios.post(API_URL+'Uploads/Photo',{Title:fileUploaded.name.toString().split('.')[0],Code:imageCode,Type:'Logo'}).then(res=>{
       
       
         toast.warn("Image Uploading");
         setTimeout(()=>{
           if(res.data.Meta.Status==0)  toast.error(res.data.Meta.Message);        
           else {
            setLogoFileName(res.data.FullPath)
            setFieldValue("LogoPictureId", res.data.Data.Id);
            toast.success(res.data.Meta.Message);
           }
         },1000)
        
        
      })
    }
  };
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      
      <CustomTextField
             InputProps={{
               classes: {
                 root: classes.textFieldInput
               },
             }}
            variant="outlined"
            id="Name"
            name="Name"
            helperText={touched.Name ? errors.Name : ""}
            error={touched.Name && Boolean(errors.Name)}
            label="Name"
            value={Name}
            onChange={change.bind(null, "Name")}
            size="small"
            className={classes.marginBottom}
          />
      
      <CustomTextField
             InputProps={{
               classes: {
                 root: classes.textFieldInput
               },
             }}
            select
            id="ShopTypeId"
            label="Select Shop Type"
            name="ShopTypeId"
            helperText={touched.ShopTypeId ? errors.ShopTypeId : ""}
            error={touched.ShopTypeId && Boolean(errors.ShopTypeId)}
            value={ShopTypeId}
            onChange={change.bind(null, "ShopTypeId")}
            variant="outlined"
            size="small"
            className={classes.marginBottom}
          >
            {shopTypes.map(option => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.Title}
              </MenuItem>
            ))}
          </CustomTextField>
       
        
     
   
              
          <div   className={classes.uploadField}>
        <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            disabled
            variant="outlined"
            name="upload"
            size="small"
            value="Select Logo"
            className={classes.marginBottom}
          />
      <input type="file"
             ref={hiddenFileInput}
             onChange={handleFileChange}
             style={{display:'none'}} 
      /> 
          <Button
         onClick={handleUploadLogo}
          size="small"
          variant="contained"
          color="primary"
          className={classes.btnUpload}
         
        >
          Choose
          </Button>
        </div>
        
         
    <Avatar alt={Name} src={logoFileName==""?(BASEIMAGE+'/Upload/Default/sbx-shop-default-logo.png'): (BASEIMAGE+ logoFileName)} className={classes.large}  />
                         
       
        
      <Button
          
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          className={classes.marginBottom}
          disabled={!isValid}
          style={{borderRadius:25,textTransform: "none",padding:'9px 25px'}}
          
        >
          Create Shop
          </Button>
          <ToastContainer autoClose={2000} />
    </form>
  );
};

export default AddShop;








