import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup"
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import LoginForm from "./LoginForm";
import x from "../../images/x.png";
import Paper from "@material-ui/core/Paper";
import AuthService from "../../services/AuthService";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { API_URL } from "../../Constants";
import { withRouter } from 'react-router-dom';

var jwtDecode = require('jwt-decode');
const useStyles = theme => ({

  paper: {
    margin: 'auto',
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth:450,
    maxHeight:500,
    borderRadius:15,
    
  },
  avatar: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(7),
    height: theme.spacing(8)
  },
 
  circle:{
    height:'100px',
    width:'100px',
    borderRadius: '100px',
    background:'#ffffff',display:'flex',
    justifyContent:'center',
    position: 'relative',
    top: '-50px'
}
 
});
const  LoginFormFormic=props=>{
  const history = useHistory();
  let [loading,setLoading]=React.useState(false)

 const submit = data => {
  setLoading(true)
  console.log(data);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer 159-'
  }
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userToken');
 axios.post(API_URL + "Token",data,{headers:headers})
  .then(response => {
      console.log(response);
      console.log('From Method');
    if (response.data.Data) {
     
     // var decoded = jwtDecode(response.data.Data);
      /// console.log(decoded);
      localStorage.setItem("userToken", response.data.Data);
      if (localStorage.getItem('userToken')) {
        axios.post(API_URL+'UserInfoes/Login',data).then(res=>{
          setLoading(false)
          console.log(res);
          if(res.data.Meta.Status==1){
            setLoading(false)
            toast.success("LoggedIn successfully");
            localStorage.setItem("user",JSON.stringify(res.data.Data))
            localStorage.removeItem('accessToken');
            if(props.location.state)  history.push(props.location.state.redirectPath)
            else  history.push('/')
            setLoading(false)
          }
        })

        }
    }else{
      toast.error("Wrong Username/Password");
    }
  
  })
     
      
      }
    
  
const validationSchema = Yup.object({
  
    LoginId: Yup.string("Enter your Mobile Number")
    .required("Mobile Number is required")
    .matches(
      /^(01[3456789])(\d{8})$/,
      "Please Enter valid Bangladeshi Phone Number"
    ),
    Password: Yup.string("")
    .min(4, "Password must contain at least 4 characters")
    .required("Enter your password")
   })


  

   const values = { LoginId: "", Password: "" };

    const { classes } = props;
   
 
   return (
    <React.Fragment >
   
      <Typography component="h1" variant="h4" align="center" color="secondary" style={{marginTop:20}}>
        Lets Explore The Best Buying Experience
      </Typography>
      <h2  align="center" style={{color:'#ffffff'}}>
       or <Link to="/create-account" style={{color:'#D1152C'}}>Create Account</Link> If Not Registered
      </h2>
    
      <Paper className={classes.paper}>
        <div className={classes.circle}>
        <img className={classes.avatar} src={x}/>
        </div>
     
     
           <Formik
             render={props => <LoginForm {...props} loading={loading} />}
             initialValues={values}
             validationSchema={validationSchema}
             onSubmit={submit}
           />
      
    
        </Paper>
        <ToastContainer autoClose={2000} />
    
    </React.Fragment>
   );
 }
 export default withRouter(withStyles(useStyles)(LoginFormFormic))