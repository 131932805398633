import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Container, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import AdCard from "../AD/AdCard";
import RightSideBar from '../RightSideBar';
import axios from 'axios'
import { API_URL } from "../../Constants";
import SkeletonCard from "../../layouts/SkeletonCard";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

export default function ShopDetail({shopId}) {

  const [value, setValue] = React.useState(0);
  const [items,setItems]=React.useState([])
  const [routeText,setRouteText]=React.useState('TopPick')//ByShop?shopId='+12
  const [loading, setLoading] = React.useState(false);
 
  React.useEffect(() => {
    setLoading(true);
    axios.get(API_URL+'Products/ByShop?shopId='+12)
     .then(response => {
       console.log(response)
       setItems(response.data)
       setLoading(false);
    });
   
   }, []);
//infinitity Scroll start
 
  const [hasMore,setHasMore]=React.useState(true)
 let[page,setPage]=React.useState(0);
 const fetchMoreData=()=>{
  console.log(routeText)
   page++;
   setPage(page);
      axios.get(API_URL+'Products/'+routeText+'?pageIndex='+page)
      .then(response => {
        setItems(items.concat(response.data))
       
     });
     
  
  }
 //infinity Scroll end
  const handleChange = (event, newValue) => {
    if(newValue==0)  {
      setLoading(true);
      setRouteText('TopPick');
      axios.get(API_URL+'Products/ByShop?shopId='+shopId)
      .then(response => {
       console.log(response)
       setItems(response.data)
       setLoading(false);
    });
     }
  
    if(newValue==1){
      setLoading(true);
     setRouteText('AllDev');
     axios.get(API_URL+'Products/AllDev')
     .then(response => {
       console.log(response)
       setItems(response.data)
       setLoading(false);
    });
   
    }  
     setValue(newValue);
   };
 
  return (
    
  items.length===0?<h5>No Products Available</h5>:
  <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={ <ul style={{listStyleType: 'none'}} className="list">
            {Array(3)
              .fill()
              .map((item, index) => (
                <li className="card" key={index}>
                  <Skeleton width={`66%`} height={210} />
                  <h4 className="card-title">
                    <Skeleton height={2} width={`66%`} />
                  </h4>
                 
                </li>
              ))}
          </ul>}
            
            scrollableTarget="scrollableDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            
            <Grid container > 
              <Grid item lg={12} md={12}>
             
               
                <Paper style={{flexGrow: 1,marginTop:10}}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          centered
                        >
                          <Tab label="My Trade" />
                          <Tab label="All Advertisement" />
                        </Tabs>
                </Paper>
             
                 {loading && <SkeletonCard />}
                 {!loading &&           
              items.map((i,index)=>(
               <AdCard item={i} key={index}/>
              ))
              }
              </Grid>
              
            
            </Grid>
          

          </InfiniteScroll>  

  );
}
