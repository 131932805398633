import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import LOGO from '../../images/sundarbanX-logo.png';
import { Formik } from "formik";
import * as Yup from "yup";
import PostAdForm from "./PostAdForm";
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { API_URL } from "../../Constants";
import EditAdForm from "./EditAdForm";
const useStyles = makeStyles((theme) => ({
  
  
  topSquare:{
     width:'60px',
     height:'60px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
     cursor:'pointer'
  },
  logo: {
    maxWidth: 160,
  },
  borderBottom:{
    borderBottom:'2px solid #D1152D'
  },
  triangleBottomRight:{
    width:0,
    height:0,
    borderRight:'150px solid red',
    borderTop:'40px solid transparent',
    borderLeft:'400px solid transparent',
    borderBottom:'60px solid red',
    
},
flexContainer:{
  display:'flex',
  justifyContent:'flex-end',
}
 
}));
const EditAdDialog = ({ isOpen, handleClose, title,advertisementGuid }) => {

  
    let [advertisement,setAdvertisement]=React.useState({})
    let [categoryIds,setCategoryIds]=React.useState([])
    React.useEffect(() => {
        axios.get(API_URL+'Products/DetailsByGuId?guid='+advertisementGuid)
        .then(response => {     
         setAdvertisement(response.data.Data);
         setCategoryIds(response.data.Data.ProductCategoryTreeWithId.split("=>"));
 
       });
    
       }, []);
       const submit = (data,{resetForm}) => {
          console.log(data)
   
        let user=JSON.parse(localStorage.getItem('user'));
       
        let categoryId=0;
        if(data.RootCategoryId){  
          categoryId=data.RootCategoryId;
        }
        if(data.SubCategoryId){  
         categoryId=data.SubCategoryId;
       }
       if(data.SubSubCategoryId){
         
         categoryId=data.SubSubCategoryId;
       }
       
         advertisement.Title=data.Title;
         advertisement.RegularPrice=data.AskingPrice;
         advertisement.MarketPrice=data.MarketPrice;
         advertisement.AllImageIds=data.NewUploadedImageIds.newUploadedImageIds;
         advertisement.Description=data.Description.replace(/\n/g,'<br />');
         advertisement.ShopId=data.ShopId;
         advertisement.ProductCategoryId=categoryId;
         advertisement.Phone=user.Phone ;
         advertisement.OwnerId= user.Id;
         advertisement.StateId= user.StateId;
         advertisement.CityId= user.CityId;
         advertisement.UpdatedBy= user.Id;
         advertisement.StateName=user.StateName;
         advertisement.CityName= user.CityName;
         advertisement.ActiveStatus=true;
                   console.log(advertisement)
           axios.put(API_URL+'Products',advertisement).then(response=>{
             console.log(response);
            if(response.data.Meta.Status==1){
             toast.success("Post Updated Successfully");
            //  resetForm({});
              setTimeout(()=>{
               handleClose();
              },3000)
             
             
            }else{
              toast.error("Something went wrong");
            }
     })        
    
       };
      const validationSchema = Yup.object().shape({
         Title: Yup.string("Enter Post Title").required(
           "Title is Required"
         ).min(10,"Title lenght must be min 10 Characters")
         .max(100,"Title lenght must be max 1000 Characters"),
         AskingPrice:Yup.number().required("Asking Price Is Required").min(1).integer(),
         RootCategoryId:Yup.string().required("You must select one Category.")
       });
  
   const values = { 
     Id:advertisement.Id,
     Title: advertisement.Title,
    RootCategoryId:categoryIds[0],
    SubCategoryId:categoryIds[1], 
    SubSubCategoryId:categoryIds[2],
    AskingPrice:advertisement.RegularPrice,
    MarketPrice:advertisement.MarketPrice,
    Description:advertisement.Description,
    ShopId:advertisement.ShopId,
    GUID:advertisement.GUID,
    ActiveStatus:advertisement.ActiveStatus,
    PictureList:advertisement.PictureListView
     };
  const classes = useStyles();
 
  
  return (
    <>
    
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        scroll='body'
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
         
         <Grid container>
               <Grid item md={2} className={classes.borderBottom}>
               <div onClick={handleClose} className={classes.topSquare}> 
                  <ArrowBackIosOutlinedIcon  color="secondary"/>     
              </div>
               </Grid>

               <Grid className={classes.borderBottom} item md={6}>
                  <Typography  style={{paddingTop:15}} color="primary" variant="h5">{title}</Typography>
              </Grid>

              <Grid item md={3}>
              <img src={LOGO} alt="logo" className={classes.logo} />
             </Grid>
             <Grid  item  md={1}>
            <CancelPresentationTwoToneIcon onClick={handleClose}  style={{paddingLeft:25}}/>
             </Grid>
         </Grid>
         
         <Formik
            render={props => <EditAdForm {...props}  />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
            enableReinitialize
          />
             <ToastContainer autoClose={2000} />
      </Dialog>
     
    </>
  );
};

EditAdDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  advertisementGuid:PropTypes.string.isRequired
};

export default EditAdDialog;
