import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { GridList, GridListTile, Typography } from "@material-ui/core";
import ProfilePic from "../../images/Avatar1.jpg";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from 'react-router-dom';
import {withStyles} from "@material-ui/core/styles";
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { API_URL, BASEIMAGE, IMAGE_URL }  from '../../Constants';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner'


const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
        
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  root:{
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
  },
  marginAll: {
    marginBottom: theme.spacing(2),
  },
  textFieldInput: {  
    width: '300px',
  },
  uploadField:{
    position: 'relative',
    display: 'inline-flex',
    background:'#ffffff'
  },
  btnUpload: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius:25,
    textTransform: "none",
    padding:'9px 35px'
    
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin:'10px 0'
  },
  banner:{
    width: theme.spacing(30),
   // height: theme.spacing(15),
    margin:'10px 0'
  }
  
}));
const EditProfile = props => {
  const classes = useStyles();
  const history = useHistory();
  const {
    values: { Name,Phone, Email, StateId, CityId, Address,ProfilePicPath,BannerPicturePath,CreatedBy},
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
    isSubmitting
  } = props;

  let [states, setStates] = React.useState([])
  let [cities, setCities] = React.useState([])
  let [districtid, setDistrictid] = React.useState(0)
  let [profilePicFileName,setProfilePicFileName]=React.useState("")
  let [bannerPicFileName,setBannerPicFileName]=React.useState("")
  let [profileUploadStatus,setProfileUploadStatus]=React.useState(false)
  let [bannerUploadStatus,setBannerUploadStatus]=React.useState(false)

  React.useEffect(() => {
    axios.get(API_URL+'States')
     .then(response => {
       console.log(response)
       setStates(response.data)
      
    });
    axios.get(API_URL+'Cities')
    .then(response => {
      console.log(response)
      setCities(response.data)
     
   });
   }, []);


  const change = (name, e) => {
    if (name === "StateId") {
      setDistrictid(e.target.value);
    }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
//profile pic upload
  const hiddenProfilePicFileInput = React.useRef(null);

  const handleUploadProfilePic = event => {
    hiddenProfilePicFileInput.current.click();
   
  };

 const handleProfilePicFileChange = event => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    let reader=new FileReader();
    reader.readAsDataURL(fileUploaded);

    reader.onloadend=e=>{
      let imageCode = reader.result.toString().split(',')[1];
          
       axios.post(API_URL+'Uploads/Photo',{Title:fileUploaded.name.toString().split('.')[0],Code:imageCode,Type:'Profile'}).then(res=>{         
        setProfileUploadStatus(true)
         toast.warn("Image Uploading");
         setTimeout(()=>{
           if(res.data.Meta.Status==0)  toast.error(res.data.Meta.Message);        
           else {
            setProfilePicFileName(res.data.FullPath)
            setFieldValue("ProfilePictureId", res.data.Data.Id);
            toast.success(res.data.Meta.Message);
           
           }
         },1000)
         
      })
    }
  };
//banner pic upload
  const hiddenBannerPicFileInput = React.useRef(null);

  const handleUploadBannerPic = event => {
    hiddenBannerPicFileInput.current.click();
  };

 const handleBannerPicFileChange = event => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    let reader=new FileReader();
    reader.readAsDataURL(fileUploaded);

    reader.onloadend=e=>{
      let imageCode = reader.result.toString().split(',')[1];
          
       axios.post(API_URL+'Uploads/Photo',{Title:fileUploaded.name.toString().split('.')[0],Code:imageCode,Type:'Banner'}).then(res=>{         
        setBannerUploadStatus(true)
         toast.warn("Image Uploading");
         setTimeout(()=>{
           if(res.data.Meta.Status==0)  toast.error(res.data.Meta.Message);        
           else {
            setBannerPicFileName(res.data.FullPath)
            setFieldValue("BannerPictureId", res.data.Data.Id);
            toast.success(res.data.Meta.Message);
           
           }
         },1000)
         
      })
    }
  };

  //banner pic upload end

  return (
    <form  onSubmit={handleSubmit} className={classes.root}>
      
             <CustomTextField
             InputProps={{
               classes: {
                 root: classes.textFieldInput
               },
             }}
               variant="outlined"
               id="Name"
               name="Name"
               helperText={touched.Name ? errors.Name : ""}
               error={touched.Name && Boolean(errors.Name)}
               label="Full Name"
               value={Name || ''}
               onChange={change.bind(null, "Name")}
               size="small"
               className={classes.marginAll}
             />
      
          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="Phone"
            name="Phone" 
            helperText={touched.Phone ? errors.Phone : ""}
            error={touched.Phone && Boolean(errors.Phone)}
            label="Phone Number"
            value={Phone || ''}
            onChange={change.bind(null, "Phone")}
            size="small"
            className={classes.marginAll}
            
          />
          <CustomTextField
            InputProps={{
              classes: {
                root: classes.textFieldInput
              },
            }}
            variant="outlined"
            id="Email"
            name="Email"
            helperText={touched.Email ? errors.Email : ""}
            error={touched.Email && Boolean(errors.Email)}
            label="Email (Optional)"
            value={Email || ''}
            onChange={change.bind(null, "Email")}
            size="small"
            className={classes.marginAll}
          />
         <CustomTextField
          InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
           
            select
            id="StateId"
            label="Select District"
            name="StateId"
            value={StateId || 0}
            onChange={change.bind(null, "StateId")}
            variant="outlined"
            size="small"
            className={classes.marginAll}
          >
            {states.map(option => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.Name}
              </MenuItem>
            ))}
          </CustomTextField>
        
          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
             
            select
            id="CityId"
            label="Select Location"
            name="CityId"
            value={CityId || 0}
            onChange={change.bind(null, "CityId")}
            variant="outlined"
            size="small"
            className={classes.marginAll}
          >
            {(cities.filter(x=>x.StateId === StateId)).map(option => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.Name}
              </MenuItem>
            ))}
          </CustomTextField>


          <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="Address"
            name="Address"
            helperText={touched.Address ? errors.Address : ""}
            error={touched.Address && Boolean(errors.Address)}
            label="Address"          
            value={Address || ''}
            onChange={change.bind(null, "Address")}
            size="small"
            className={classes.marginAll}
          />
            


   {/* upload profile picture */}
            <div   className={classes.uploadField}>
        <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            disabled
            variant="outlined"
            name="UploadProfilePic"
            size="small"
            value="Select Profile Picture"
            className={classes.marginBottom}
          />
      <input type="file"
             ref={hiddenProfilePicFileInput}
             onChange={handleProfilePicFileChange}
             style={{display:'none'}} 
      /> 
          <Button
         onClick={handleUploadProfilePic}
          size="small"
          variant="contained"
          color="primary"
          className={classes.btnUpload}
         
        >
          Choose
          </Button>
        </div>
      
           <Avatar variant="circle" alt={Name} src={profileUploadStatus?(BASEIMAGE+ profilePicFileName): (IMAGE_URL+ProfilePicPath)} className={classes.large} />
     
        {/* upload banner picture */}

        <div   className={classes.uploadField}>
        <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            disabled
            variant="outlined"
            name="UploadBannerPic"
            size="small"
            value="Select Banner Picture"
            className={classes.marginBottom}
          />
      <input type="file"
             ref={hiddenBannerPicFileInput}
             onChange={handleBannerPicFileChange}
             style={{display:'none'}} 
      /> 
          <Button
         onClick={handleUploadBannerPic}
          size="small"
          variant="contained"
          color="primary"
          className={classes.btnUpload}
         
        >
          Choose
          </Button>
        </div>
        
        <img alt={Name} src={bannerUploadStatus?(BASEIMAGE+ bannerPicFileName): (IMAGE_URL+BannerPicturePath)} className={classes.banner} />
         

          <CustomTextField
            type="hidden"
            value={CreatedBy}
          
          />
        <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          className={classes.marginAll}
          disabled={!isValid || isSubmitting}
          style={{borderRadius:25,textTransform: "none",padding:'9px 25px'}}
        >
         Update Profile
          </Button>
   
    </form>
  );
};

export default EditProfile;
