import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AddShop from "./AddShop";
import { Paper } from "@material-ui/core";
import axios from 'axios'
import { API_URL } from "../../Constants";
import "react-toastify/dist/ReactToastify.css";
import { toast,ToastContainer } from "react-toastify";
import EditShop from "./EditShop";
import { useParams } from "react-router-dom";


const useStyles = theme => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
   // alignItems: 'center',
    maxWidth:700,
    maxHeight:1300,
   
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom:'20px'
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  topLabel:{
     width:'250px',
     height:'40px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
  },
  rightTopHalfCircle:{
    height:'40px',
    width:'20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
   
},
bottomRightTriangle:{
     width:'30px',
     height:'30px',
     background: '#D1152D',
     borderRadius: `90px 0 0px 0`
},

});
  const  EditShopFormic=(props)=> {
    let { guid } = useParams();
      let [shop,setShop]=React.useState({})
    React.useEffect(() => {
        axios.get(API_URL+'Shops/DetailsByGuId?guid='+guid)
        .then(response => {
          console.log(response)
          setShop(response.data.Data)
         
       });
      
    
       }, []);
    
 const submit =  (data,{resetForm}) => {
   console.log(data)
  let userInfo=JSON.parse(localStorage.getItem('user'));
     
        shop.ShopTypeId = data.ShopTypeId;
        shop.Name= data.Name;
        shop.LogoPictureId= data.LogoPictureId;
        shop.SignboardPictureId= data.SignboardPictureId;  
        shop.Phone= data.Phone;
        shop.Email=data.Email;
        shop.StateId=data.StateId;
        shop.CityId=data.CityId;
        shop.Address=data.Address;
        shop.UpdatedBy= userInfo.Id;
        

        console.log(shop)
    
        axios.put(API_URL+'Shops',shop).then(response=>{        
          toast.success("Shop Updated Successfully");
           resetForm({});  
        },error=>{
          toast.error("Something went wrong");
         })
         toast.success("Shop Updated Successfully");
      };

 const validationSchema = Yup.object({
        Name: Yup.string("Enter a name").required("Name is required"),
        ShopTypeId: Yup.string("Select Shop Type").required(
            "Shop Type is required"
          ),
     
  });

 const values = {
    Name: shop.Name,ShopTypeId:shop.ShopTypeId,Phone:shop.Phone,Email:shop.Email,
    StateId:shop.StateId,CityId:shop.CityId,Address:shop.Address,
    LogoPath:shop.LogoPath,LogoPictureId:shop.LogoPictureId,
    SignboardPath:shop.SignboardPath,SignboardPictureId:shop.SignboardPictureId
  };

  const { classes } = props;
    

    return (
    
     <Paper className={classes.paper}>

         <div className={classes.flexContainer}>
         <div className={classes.topLabel}>
         <Typography variant="body1"  color="secondary">
          Edit Shop
        </Typography>  </div>
        <div className={classes.rightTopHalfCircle}>  </div>
         </div>
      
      
          <Formik
           enableReinitialize
            render={props => <EditShop {...props} />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
          />
         <div className={classes.flexZone}>
      
        <div className={classes.bottomRightTriangle}>  </div>
         </div>
         <ToastContainer autoClose={2000} />
        </Paper>
    );
  }

export default withStyles(useStyles)(EditShopFormic);

