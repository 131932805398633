import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Route } from "react-router-dom";
import { Grid, Typography, Container} from "@material-ui/core";
import LeftSideBar from "../components/LeftSideBar";
import {useHistory} from 'react-router-dom';

import { grey} from '@material-ui/core/colors';
import NavBar from "../components/Navbar";
import BannerTop from "../images/shop-banner.png"

const useStyles = makeStyles(theme => ({
  box:{
    position: 'relative'
},


}));
const DetailsPageLayout = ({ children, ...rest }) => {
  const classes = useStyles();
  const history=useHistory();

  const [isAuthenticated,setIsAuthenticated]=React.useState(false)

  React.useEffect(()=>{
   if(localStorage.getItem('user')) setIsAuthenticated(true)
  },[])

const  handleAuthenticate=()=>{
    setIsAuthenticated(false)
  }
  
  return (
    <div style={{backgroundColor:'#F2F2F2',width: '100%', height: '100%',position: 'absolute', top: 0, left: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'}}>
      <Grid container >
          <Grid item> <NavBar authenticate={handleAuthenticate} isAuthenticated={isAuthenticated}/></Grid>
      </Grid>
     

      <Container maxWidth="xl" style={{paddingTop: '75px',backgroundColor:'#F2F2F2'}}>
    <Grid container >
      <Grid item xl={3} lg={3} md={3} sm={3}>
        <LeftSideBar isAuthenticated={isAuthenticated} />
      </Grid>

      <Grid item xl={9} lg={9} md={9} sm={9}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <div className="main">{children}</div>
          </Grid>
        </Grid>
      </Grid>

  
    </Grid>
    </Container>
    </div>
  );
};

const DetailsPageLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <DetailsPageLayout>
          <Component {...matchProps} />
        </DetailsPageLayout>
      )}
    />
  );
};

export default DetailsPageLayoutRoute;
