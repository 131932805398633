



class AuthService{
  logout(){
    localStorage.removeItem('userToken')
    localStorage.removeItem('user')
    
  }
  isLoggedIn(){
     return !!localStorage.getItem('user');
  }
}

export default new AuthService;



