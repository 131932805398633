import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LOGO from '../images/sundarbanX-logo.png';
import { Toolbar, Button, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PostAdDialog from './AD/PostAdDialog';
import AuthService from '../services/AuthService'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  topQuarterCircle:{
    width:'30px',
     height:'30px',
     background: '#D1152D',
     borderRadius: `0 0 90px 0`
  },
  halfCircleBottom:{
    height:'25px',
    width:'50px',
    borderRadius: '0 0 90px 90px',
    background: '#D1152D',
    marginRight: '243px'
},
  logo: {
    maxWidth: 160,
  },
 
}));

 const  NavBar=({authenticate,isAuthenticated})=> {
  const classes = useStyles();
  const history=useHistory();
  const [isOpen, setIsOpen] = React.useState(false)

  const handleCreateShop=()=>{
    if(!localStorage.getItem('user')){
      history.push('/login',{redirectPath:'/add-shop'})
  }else{
    history.push('/add-shop')
  }
  }
  
 
  const handleReferFriend=()=>{
    if(!localStorage.getItem('user')){
      history.push('/login',{redirectPath:'/refer-friend'})
  }else{
    history.push('/refer-friend')
  }
  }
 const handleDialogOpen = () => {
    if(!localStorage.getItem('user')){
      history.push('/login')
  }
      setIsOpen(true)
  }

  const handleDialogClose = () => {
      setIsOpen(false)
      
  }
  return (
    <div style={{position:'fixed',backgroundColor: '#ffffff',width: '100%',  margin: 'auto', zIndex:1}}>
         {/* <div className={classes.flexContainer}>
         <div className={classes.topQuarterCircle}>  </div>
        <div className={classes.halfCircleBottom}>  </div>
         </div> */}
        
        <Toolbar style={{marginLeft: 160 }}>
        <img onClick={()=>history.push('/')}  style={{cursor:'pointer',marginRight: 160 }} src={LOGO} alt="logo" className={classes.logo} />

        <Button style={{ borderRadius: 25 ,marginRight: 20,textTransform: "none"}} variant="outlined" onClick={handleDialogOpen} color="primary" >Create Advertisement</Button>
        {/* <Button style={{textTransform: "none"}}>About</Button>
        <Button style={{textTransform: "none"}}>Category</Button> */}
        <Button style={{ borderRadius: 25 ,marginRight: 20,textTransform: "none"}} variant="outlined" onClick={handleCreateShop}  >Create Shop</Button>
        <Button style={{ borderRadius: 25,marginRight: 15,textTransform: "none" }} variant="outlined" onClick={handleReferFriend}>Refer</Button>
        {
          isAuthenticated?
          <Button onClick={()=>{localStorage.clear();authenticate()}} style={{ borderRadius: 25,textTransform: "none" }} variant="contained" color="primary">Logout</Button>
          :<Button onClick={()=>history.push('/login')} style={{ borderRadius: 25,textTransform: "none" }} variant="contained" color="primary">LogIn</Button>
        }
        
        </Toolbar>

        <PostAdDialog
            isOpen={isOpen}
            handleClose={handleDialogClose}
            title='Create Advertisement'
        >
        </PostAdDialog>
      </div>
  );
}

export default NavBar;