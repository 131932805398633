import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from 'axios'
import AdCard from "./AdCard";
import RightSideBar from "../RightSideBar";
import { API_URL } from "../../Constants";


export default function MyAdList() {
  
  const [items,setItems]=React.useState([])
  let userInfo=JSON.parse(localStorage.getItem('user'));
  React.useEffect(() => {
    axios.get(API_URL+'Products/ByUser?userId='+userInfo.Id)
     .then(response => {
       console.log(response)
       setItems(response.data)
      
    });
   
   }, []);
//infinitity Scroll start
 
  const [hasMore,setHasMore]=React.useState(true)
 let[page,setPage]=React.useState(0);
 const fetchMoreData=()=>{
   page++;
   setPage(page);
      axios.get(API_URL+'Products/ByUser?userId='+userInfo.Id+'&pageIndex='+page)
      .then(response => {
        if(response.data.length<10) setHasMore(false);
        setItems(items.concat(response.data))
       
     });
      
  
  }

  //infinity Scroll end
  return (
 
       <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            
            scrollableTarget="scrollableDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            
            <Grid container > 
              <Grid item lg={12} md={12}>
             
              {
              items.map((i,index)=>(
               <AdCard item={i} key={index} displayEditBtn={true}/>
              ))
              }
              </Grid>
              
              
            </Grid>
          

          </InfiniteScroll>
     

  );
}
