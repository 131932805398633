import React from "react";
import Skeleton from "react-loading-skeleton";
const SkeletonCard = () => {
    return (
      <section>
        

        <ul style={{listStyleType: 'none'}} className="list">
          {Array(4)
            .fill()
            .map((item, index) => (
              <li className="card" key={index}>
                <Skeleton height={210} />
                <h4 className="card-title">
                  <Skeleton height={2} width={`100%`} />
                </h4>
               
              </li>
            ))}
        </ul>
      </section>
    );
  };
  export default SkeletonCard;
