import React from 'react';
import { Typography, Paper } from '@material-ui/core';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';
import {withStyles} from "@material-ui/core/styles";


const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
        
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  root:{
    position: 'relative',
    display: 'inline-flex',
    background:'#ffffff'
  },
  btnSearch: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius:25,
    textTransform: "none",
    padding:'9px 40px'
    
  },
  textFieldInput: {  
   minWidth:'500px',
   maxWidth:'630px'
  },
 
  
}));

const SearchProduct=props=>{
    const classes = useStyles();
    return(
        <>
      
        <form   className={classes.root}>
        <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="SearchText"
            name="SearchText"
            size="small"

            
          />

          <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          className={classes.btnSearch}
         
        >
          Search
          </Button>
        </form>

        </>
    )
}

export default SearchProduct;