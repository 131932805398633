import React, { useEffect, useState } from 'react';
import { Paper, Typography,Button, Grid } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../Constants';
import {useHistory} from 'react-router-dom'
import SecondarySkeletonCard from '../../layouts/SecondarySkeletonCard';

const useStyles=makeStyles((theme)=>({
  root:{
     height:'280px', 
     marginBottom:theme.spacing(2)    
  },
  
  title:{
      borderBottom:'1px solid #D1152C',
      width:'196px',
      marginLeft: '20px',
      marginBottom:'20px',
      display: 'block',
      content: ""
  },
  spec: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft:'20px',
    marginBottom:'15px'    
  },
}));

const LatestAd=props=>{
 const classes=useStyles();
 const history=useHistory();
 const [loading, setLoading] = React.useState(false);
 
    let [latestProducts,setLatestProducts]=useState([])
     useEffect(()=>{
      setLoading(true)
      axios.get(API_URL+'Products/Latest?pageSize='+5)
      .then(response => {
        console.log(response)
        setLatestProducts(response.data)
        setLoading(false)
     });
     },[])

    return(
    
        <Paper className={classes.root}>
        <Typography variant="h6" color="primary" className={classes.title}>Latest Advertisement</Typography>

        {loading && <SecondarySkeletonCard />}
      {!loading &&
          latestProducts?.map((product,i)=>(

<div key={i} className={classes.spec}>
<Typography  variant="subtitle2" color="primary" style={{marginRight:'30px'}}>
{product?.UpdatedAtString}
</Typography>
<Typography variant="subtitle2" style={{marginRight:'3px'}}>
{product?.Title}
</Typography>
<Typography  variant="body2"  style={{marginRight:'3px'}}>
From
</Typography>
<Typography variant="subtitle2" style={{marginRight:'3px'}}>
 {product?.CollectionAddress}
</Typography>
<Typography variant="body2"  style={{marginRight:'3px'}}>
Asking Price 
</Typography>
<Typography variant="subtitle2" style={{marginRight:'3px'}}>
{product?.RegularPrice}
</Typography>
<Typography  variant="body2" style={{marginRight:'3px'}}>
Market Price 
</Typography>
<Typography variant="subtitle2" style={{marginRight:'3px'}}>
{product?.MarketPrice}
</Typography>
<Link   onClick={()=>{const win = window.open("/"+product.GUID, "_blank");
                                win.focus();}}>More</Link>
{/* <Button  onClick={()=>history.push('/'+product?.GUID)}  size="small" style={{ borderRadius: 25,textTransform: "none" }} variant="contained" color="primary">Details</Button> */}
</div>


        ))}
        {/* <Grid container justify="center">
        <Button
        type="submit"
        size="small"
        variant="contained"
        color="primary"
        style={{borderRadius:25,textTransform: "none",padding:'6px 35px',marginTop:'25px'}}
      >
        Show All
        </Button>
        </Grid> */}
       

      </Paper>
    )
}

export default LatestAd;