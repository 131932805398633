import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import LOGO from '../../images/sundarbanX-logo.png';
import { Formik } from "formik";
import * as Yup from "yup";
import PostAdForm from "./PostAdForm";
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Fab,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { API_URL } from "../../Constants";
const useStyles = makeStyles((theme) => ({
  
  
  topSquare:{
     width:'60px',
     height:'60px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
     cursor:'pointer'
  },
  logo: {
    maxWidth: 160,
  },
  borderBottom:{
    borderBottom:'2px solid #D1152D'
  },
  triangleBottomRight:{
    width:0,
    height:0,
    borderRight:'150px solid red',
    borderTop:'40px solid transparent',
    borderLeft:'400px solid transparent',
    borderBottom:'60px solid red',
    
},
flexContainer:{
  display:'flex',
  justifyContent:'flex-end',
}
 
}));
const PostAdDialog = ({ isOpen, handleClose, title }) => {
  
 const submit = (data,{resetForm}) => {
   let user=JSON.parse(localStorage.getItem('user'));
   let categoryId=0;
   if(data.RootCategoryId){  
     categoryId=data.RootCategoryId;
   }
   if(data.SubCategoryId){  
    categoryId=data.SubCategoryId;
  }
  if(data.SubSubCategoryId){
    
    categoryId=data.SubSubCategoryId;
  }
   let ad={
     Title:data.Title,
     RegularPrice:data.AskingPrice,
     MarketPrice:data.MarketPrice,
     AllImageIds:data.AllImageIds.allImageIds,
     Description:data.Description.replace(/\n/g,'<br />'),
     ProductCategoryId:categoryId,
     Phone:user.Phone ,
     OwnerId: user.Id,
     StateId: user.StateId,
     CityId: user.CityId,
     CreatedBy: user.Id,
     StateName: user.StateName,
     CityName: user.CityName,
     ActiveStatus:true
    }
    console.log(ad)
   
      axios.post(API_URL+'Products',ad).then(response=>{
        console.log(response);
       if(response.data.Meta.Status==1){
        toast.success("Post added Successfully");
         resetForm({});
         setTimeout(()=>{
          handleClose();
         },3000) 
       }else{
         toast.error("Something went wrong");
       }
})
    
    
  
   
  };
 const validationSchema = Yup.object().shape({
    Title: Yup.string("Enter Post Title").required(
      "Title is Required"
    ).min(10,"Title lenght must be min 10 Characters")
    .max(100,"Title lenght must be max 1000 Characters"),
    AskingPrice:Yup.number().required("Asking Price Is Required").min(1,"You must enter Asking Price").integer(),
    RootCategoryId:Yup.string().required("You must select one Category.")
  });
  
   const values = { Title: "",AskingPrice:0,MarketPrice:0,Description:""};
  const classes = useStyles();
 
  
  return (
    <>
    
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        scroll='body'
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
         
         <Grid container >
               <Grid item md={2} className={classes.borderBottom}>
               <div onClick={handleClose} className={classes.topSquare}> 
                  <ArrowBackIosOutlinedIcon  color="secondary"/>     
              </div>
               </Grid>

               <Grid className={classes.borderBottom} item md={6}>
              <Typography  style={{paddingTop:15}} color="primary" variant="h5">{title}</Typography>
              </Grid>

              <Grid item md={3}>
              <img  src={LOGO} alt="logo" className={classes.logo} />
             </Grid>
             <Grid  item  md={1}>
            <CancelPresentationTwoToneIcon onClick={handleClose}  style={{paddingLeft:25}}/>
             </Grid>
         </Grid>
         
         <Formik
            render={props => <PostAdForm {...props}  />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
            
          />
             <ToastContainer autoClose={2000} />
      </Dialog>
     
    </>
  );
};

PostAdDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default PostAdDialog;
