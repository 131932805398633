import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles, Grid, GridList, GridListTile, Button, Avatar } from "@material-ui/core";
import PIC1 from '../../images/pic3.jpg';
import ReactImageMagnify from 'react-image-magnify';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { API_URL, IMAGE_URL, Base_Image_URL, BASEIMAGE } from "../../Constants";
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import AddCommentFormik from "../comments/AddComment";
import Moment from "react-moment";


const useStyles = makeStyles(theme => ({
  borderBottom: {
    borderBottom: '2px solid red',
    margin:'70px 0 30px 0',
    width:'110px'
  },
  flexContainer:{
    display:'flex',
    justifyContent:'flex-start'
  },
  rectangle1:{
    height:'40px',
    width:'180px',
    background:'#D1152C',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'5px'
},
rectangle2:{
  height:'40px',
  width:'180px',
  background:'#fff',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  alignContent:'center'
},
spec: {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
 
},
}));

const AdDetail = props => {
  const classes = useStyles()
  let { guid } = useParams()
  console.log("Guid",guid)
  let [product,setProduct]=React.useState({})
  let [tiles,setTiles]=React.useState([])
  let [tile,setTile]=React.useState({})
  let [isLoading,setIsLoading]=React.useState(true)
  let [showPhoneNumber,setShowPhoneNumber]=React.useState(false)

  React.useEffect(()=>{  
     axios.get(API_URL+'Products/Details?seoLink='+guid).then(res=>{ 
       console.log("api Url",API_URL+'Products/Details?seoLink='+guid)
       console.log(res.data.Data)
       setProduct(res.data.Data)
       if(res.data.Data.PictureListView.length==0){
        setTile({FileLinkThumb:'/Upload/Default/sbx-product-default.png',FileLinkBig:'/Upload/Default/sbx-product-default.png'})      
       }else{
        setTiles(res.data.Data.PictureListView)
        setTile(res.data.Data.PictureListView[0])
       }    
       setIsLoading(false)
     }).catch(err=>{
       console.log(err)
     })
  },[])
  
  let [imageHeight,setImageHeight]=React.useState(600)
  let [imageWidth,setImageWidth]=React.useState(1200)
 const handleImageClick=(tile)=>{
     setTile(tile);
     const img = new Image();
img.onload = function() {
  setImageHeight(this.height)
  setImageWidth(this.width);
}
img.src = BASEIMAGE+tile.FileLinkBig;
  }

  if(!isLoading){
    return (
  
      <>
        <Grid container justify="space-around" style={{marginBottom:'10px'}}>
                  <Grid item md={1}>
                    <Avatar alt="Travis Howard" src={Base_Image_URL+'Profile/'+product.CollectionImage} />
                  </Grid>
                  <Grid item md={8} >
                    <Typography  onClick={()=>{const win = window.open('/profile/'+product.CreatedBy, "_blank");
                                win.focus();}} style={{cursor:'pointer'}} variant="subtitle2">{product.CollectionName}</Typography>
                    <Typography  onClick={()=>{const win = window.open('/profile/'+product.CreatedBy, "_blank");
                                win.focus();}} style={{cursor:'pointer'}} variant="body2">{product.CollectionAddress}</Typography>
       
                  </Grid>
                 
                  <Grid item md={3}>
                    <Typography color="primary" variant="body2"><Moment format="D MMM YYYY HH:mm A" add={{ hours: 6 }}>{product.UpdatedAt}</Moment></Typography>
       
                  </Grid>
                </Grid> 
     <Grid container spacing={5}>
     <Grid container item md={4} >
       <Grid item md={12}>
     
       <ReactImageMagnify {...{
   smallImage: {
       alt: 'product',
       isFluidWidth: true,
       src: BASEIMAGE+tile?.FileLinkThumb
   },
   largeImage: {
       alt: 'product',
       src: BASEIMAGE+tile?.FileLinkBig,
       width: imageWidth,
       height: imageHeight
       
   }
}} />
       </Grid>
       <Grid style={{marginTop:10}} item md={12}>
       <GridList cols={3} rows={2} cellHeight={80}>
                       {tiles.length==0?
                       <GridListTile style={{ display: 'flex', flexFlow: 'no-wrap column' }}  >
                       <img style={{cursor:'pointer'}} onClick={()=>handleImageClick(tile)} src={BASEIMAGE+'/Upload/Default/sbx-product-default.png'}  alt="default image" />
                    </GridListTile>:
                          tiles?.map((tile,i) => (
                           <GridListTile style={{ display: 'flex', flexFlow: 'no-wrap column' }}  key={i}  >
                              <img style={{cursor:'pointer'}} onClick={()=>handleImageClick(tile)} src={BASEIMAGE+tile?.FileLinkThumb}  alt={tile.SeoFileName} />
                           </GridListTile>
                         ))
                       }
                       </GridList>
       </Grid>
    
     </Grid>
     <Grid  item md={8} >
    
         <Typography variant="h5">{product.Title}</Typography>
         <div className={classes.spec}>
                   <Typography  variant="subtitle2" color="textPrimary">
                      Asking Price :
                    </Typography>
                    <Typography variant="subtitle2" color="primary">
                    &nbsp;{product.RegularPrice}
                    </Typography>
                    &nbsp;
                    <Typography variant="subtitle2" color="primary">
                     BDT
                    </Typography>
                   </div>
                   {
                     product.MarketPrice===0?null:
                     <div className={classes.spec}>
                     <Typography  variant="subtitle2" color="textPrimary">
                        Market Price : 
                      </Typography>
                      <Typography variant="subtitle2" color="primary">
                      &nbsp;{product.MarketPrice}
                      </Typography>
                      &nbsp;
                      <Typography variant="subtitle2" color="primary">
                     BDT
                    </Typography>
                     </div>
                   }
                   

                    <div className={classes.spec}>
                  
                    <Typography variant="subtitle2" color="textSecondary">
                   {product.ProductCategoryTree}
                    </Typography>
                 
                   </div>
        
           <Grid style={{marginTop:80}} container spacing={4}>
             <Grid  item>
             <Paper  className={classes.rectangle1} onClick={()=>setShowPhoneNumber(true)}>
              <Typography  variant="h6"  color="secondary">Contact Seller</Typography>
           </Paper>
             </Grid>
             <Grid item>
               {
                 showPhoneNumber?<Paper className={classes.rectangle2} >
                 <Typography variant="h6"> <PhoneInTalkOutlinedIcon />&nbsp;{product.Phone}</Typography>
        </Paper>:null
               }
             
             </Grid>
           </Grid>
         
        
     </Grid>
    
   </Grid>

  <div className={classes.borderBottom}>
    <Typography variant="h6"  >Description</Typography>
    </div>
   
 
   <div  dangerouslySetInnerHTML={{__html: product.Description}} />
   <div className={classes.borderBottom}>
    <Typography variant="h6"  >Comments</Typography>
    </div>
  
  <div style={{ margin:'50px 0 30px 0',backgroundColor:'#F2F2F2'}}>
  <AddCommentFormik productId={product.Id}/>
  </div>
 

   </>
 );
  }else{
    return null;
  }
   
};

export default withStyles(useStyles)(AdDetail);
