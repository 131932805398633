import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup"
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PasswordRecovery from "./PasswordRecovery";
import x from "../../images/x.png";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import RegisterByMobile from "./RegisterByMobile";
import axios from 'axios';
import { API_URL } from "../../Constants";
import VerificationCode from "./VerificationCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import RegisterForm from "./RegisterForm";

const useStyles = theme => ({

  paper: {
    margin: 'auto',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth:450,
    maxHeight:700,
    borderRadius:15,
    
  },
  avatar: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(5),
    height: theme.spacing(6)
  },
 
  circle:{
    height:'70px',
    width:'70px',
    borderRadius: '70px',
    background:'#ffffff',display:'flex',
    justifyContent:'center',
    position: 'relative',
    top: '-30px'
}
 
});

const RegisterByMobileFormic =props=> {

  let [isMobile,setIsMobile]=React.useState(true);
  let [isPin,setIsPin]=React.useState(false);
  let [isRegister,setIsRegister]=React.useState(false);
  let [code,setCode]=React.useState("");
  let [phoneNumber,setPhoneNumber]=React.useState("")

  const submit1 = data => {
    setPhoneNumber(data.mobileNumber)
    axios.get(API_URL+'UserInfoes/OTPForRegistratoin?mobile='+data.mobileNumber)
    .then(response=>{
        console.log(response)
      if(response.data.Meta.Status==1){
        setIsMobile(false)
        setIsPin(true)
        setCode(response.data.Data.Code)
      }else{
        toast.error(response.data.Meta.Message+','+response.data.Data.SMSStatus)
      }
      
    })
 
  };
const validationSchema1 = Yup.object({
  
    mobileNumber: Yup.string("Enter your Mobile Number")
    .required("Please Enter Your Mobile Number")
    .matches(
      /^(01[3456789])(\d{8})$/,
      "Please Enter valid Bangladeshi Phone Number"
    )
   })

  const values1 = { mobileNumber: ""};

  const submit2 = data => {
    if(data.verificationPin==code){
      setIsPin(false)
      setIsRegister(true)
    }else{
      toast.error("Incorrect Pin");
    }

  };
const validationSchema2 = Yup.object({
  
  verificationPin: Yup.string("Enter your Verification Pin")
    .required("Verification Pin is required")
   })

  const values2 = { verificationPin: "" };

const  submit3 = data => {
    axios.post(API_URL+'UserInfoes/SignUp',data).then(res=>{
      if(res.data.Meta.Status==1){
      toast.success(res.data.Meta.Message);
      }else{
        toast.error("Something Went Wrong");
      }
    })

  };

 

 const validationSchema3 = Yup.object({
    Name: Yup.string("Enter a name")
    .required("Name is required"),
    Phone: Yup.string("Enter a Phone Number")
    .required("Phone Number is required")
    .matches(
      /^(01[3456789])(\d{8})$/,
      "Please Enter valid Bangladeshi Phone Number"
    ),
    StateId: Yup.number("Select District")
    .required("District is required"),
    CityId: Yup.number("Select Thana")
    .required("Thana is required"),
    Password: Yup.string("")
    .min(4, "Password must contain at least 4 characters")
    .required("Enter your password"),
    ConfirmPassword: Yup.string("Enter your password")
    .required("Confirm your password")
    .oneOf([Yup.ref("Password")], "Password does not match")
  
   })

   const values3 = { Name: "", Phone: phoneNumber,Email: "", Password: "",ConfirmPassword: "",CreatedBy:1 };


    const { classes } = props;
  
 
   return (
    <React.Fragment >
   
   <Typography component="h6" variant="h5" align="center" color="secondary" style={{marginTop:15}}>
        Create Your Acccount
      </Typography>
      <Paper className={classes.paper}>
        <div className={classes.circle}>
        <img className={classes.avatar} src={x}/>
        </div>
     
     {isMobile?
           <Formik
             render={props => <RegisterByMobile {...props}/>}
             initialValues={values1}
             validationSchema={validationSchema1}
             onSubmit={submit1}
           />:null
           
           
           }

{isPin?
          <Formik
             render={props => <VerificationCode {...props} />}
             initialValues={values2}
             validationSchema={validationSchema2}
             onSubmit={submit2}
           />:null
           
           }

           {isRegister?
        <Formik
             render={props => <RegisterForm {...props} />}
             initialValues={values3}
             validationSchema={validationSchema3}
             onSubmit={submit3}
           />:null}
    
        </Paper>
        <ToastContainer autoClose={4000} />
    
    </React.Fragment>
   );
 }
export default withStyles(useStyles)(RegisterByMobileFormic)