import React from "react";
import Skeleton from "react-loading-skeleton";
const SecondarySkeletonCard = () => {
    return (
      <section>
        

        <ul style={{listStyleType: 'none'}} className="list">
          {Array(5)
            .fill()
            .map((item, index) => (
              <li className="card" key={index}>
               
                <h4 className="card-title">
                  <Skeleton height={13} width={`50%`} />
                </h4>
               
              </li>
            ))}
        </ul>
      </section>
    );
  };
  export default SecondarySkeletonCard;
